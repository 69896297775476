<template>
  <div>
    <a-modal title="结算金额详情" v-model="showDetailVisible" :footer="null" :width="600">
      <div class="detail-container">
        <div class="detail-top">
          <div class="detail-bar">
            <label>业务时间:</label>
            <span class="bar-desc">{{ detailQuery.date }}</span>
          </div>

          <div class="detail-bar">
            <label>结算单状态:</label>
            <span class="bar-desc">{{ stsInfo?.name }}</span>
          </div>

          <div class="detail-bar">
            <label>结算金额:</label>
            <span class="bar-desc">{{ moneyType+ detailQuery.amount }}</span>
          </div>
        </div>
        <div class="modeal-table-wrapper">
          <el-table row-key="id" :max-height="tableHeight" :data="detailData" style="width: 100%" border>
            <el-table-column show-overflow-tooltip prop="appName" label="应用名称" align="left"></el-table-column>
            <el-table-column show-overflow-tooltip prop="placeName" label="广告位名称" align="left"></el-table-column>
            <el-table-column show-overflow-tooltip prop="amount" label="结算金额" align="left">
              <template
                slot-scope="{ row }"
              >
                {{ moneyType + row.amount }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
import { adxSettleReportDetails } from '@/apiForManage/financialManagementA'
import { mapState } from 'vuex'
export default {
  name: 'DetailPart',
  components: {},
  data () {
    return {
      detailData: [],
      detailQuery: {},
      isLoading: false,
      // 状态列表
      stsList: [
        { name: '待申请', id: '1' },
        { name: '待上传发票', id: '2' },
        { name: '审核失败', id: '3' },
        { name: '结算中', id: '4' },
        { name: '结算完成', id: '5' },
        { name: '结算失败', id: '6' }
      ],
      columns: [
        {
          title: '应用名称',
          dataIndex: 'appName',
          width: '150px',
          scopedSlots: {
            customRender: 'appName'
          }
        },
        {
          title: '广告位名称',
          dataIndex: 'placeName',
          width: '150px',
          scopedSlots: {
            customRender: 'placeName'
          }
        },
        {
          title: '结算金额',
          dataIndex: 'amount',
          width: '150px',
          scopedSlots: {
            customRender: 'amount'
          }
        }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    detailQbject: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    }),
    showDetailVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    stsInfo () {
      const sts = this.stsList.find((item) => +item.id === this.detailQuery.sts)
      return sts || null
    },
    tableHeight () {
      if (this.detailQuery.total > 0) {
        return '450px'
      } else {
        return undefined
      }
    }
  },
  created () {
    this.getData()
  },
  mounted () {},
  methods: {
    async getData () {
      const { data = {} } = await adxSettleReportDetails({ id: this.detailQbject.id, page: 1 })
      const { items = [], amount = undefined, total = undefined, date = undefined, sts = undefined } = data
      this.detailQuery = { amount: numFormat(amount, 3, ''), date, total, sts }
      this.detailData = items
      this.detailData.map((item) => {
        item.amount = numFormat(item.amount, 3, '')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-container {
  border-radius: 5px;
  min-height: 500px;
  padding: 10px;
  .detail-top {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    .detail-bar {
      padding: 0 10px 0 5px;
      font-size: 14px;
      font-weight: 400;
      color: #4b4b4b;
      line-height: 15px;
      .bar-desc {
        padding: 0 10px;
      }
    }
  }
  .modeal-table-wrapper {
    border-radius: 5px;
    padding: 0 10px 0 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
