<template>
  <div class="progress-bar-container">
    <div class="wrapper" v-if="per">
      <span class="wrapper_text">{{ per + '%' }}</span>
      <div class="box" :style="{ width: per + '%' }">
        <span class="box_text">{{ per + '%' }}</span>
      </div>
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  props: {
    per: {
      default: 0,
      type: Number
    }
  }
}
</script>

<style lang="less" scoped>
.progress-bar-container {
  display: flex;
  .wrapper {
    height: 14px;
    width: 120px;
    border-radius: 5px;
    background-color: #ddd;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 14px;
    vertical-align: middle;
    .wrapper_text {
      display: inline-block;
      position: absolute;
      left: 50px;
      top: 0;
      color: #000;
    }
    .box {
      height: 100%;
      background: @primary-color;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      border-radius: 5px;
      .box_text {
        display: inline-block;
        position: absolute;
        left: 50px;
        color: #fff;
      }
    }
  }
}
</style>
