<template>
  <a-modal
    v-model="adxSourceVisible"
    :title="(isAdd ? '添加' : '修改') + '广告源'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="isAdd ? 940 : 900"
    dialogClass="adxSource_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button
          v-if="origin === 'https://app.mediatom.cn'"
          style="margin-right: 500px"
          type="link"
          target="_blank"
          href="https://app.mediatom.cn/docs/#/?blogid=125"
        >如何配置广告源?</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
          <!-- 广告源名称 -->
          <a-form-model-item label="广告源名称" prop="name">
            <!-- {{ formquery.name }} -->
            {{ title }} ADX
          </a-form-model-item>
          <!-- 渲染方式（账户限制：全有） -->
          <a-form-model-item
            v-if="positionId === 10"
            label="渲染方式"
            :rules="[{ required: true, message: '请选择渲染方式', trigger: 'change' }]"
            prop="adStyle"
          >
            <a-select
              style="width: 34%"
              placeholder="请选择渲染方式"
              v-model="formquery.adStyle"
              showSearch
              :filter-option="filterOption"
              @change="
                () => {
                  $refs.ruleForm.clearValidate()
                }
              "
            >
              <a-select-option
                v-for="item in adStyleList"
                :key="item.id"
                :value="item.id"
                showSearch
                :filter-option="filterOption"
              >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <!-- 插屏样式、按钮位置 -->
          <!-- 关闭按钮控制 -->
          <!--
                (插屏 and 表单中的是否自渲染开启) or (api广告 and 插屏)
                -->
          <TableScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <OpenScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <OpenOrTableScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <HostSpotSourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
          <IncentiveVideoSourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
          <!-- 模板类型（账户限制：全有） -->
          <a-form-model-item
            :rules="[{ required: true, message: '请选择模版类型', trigger: 'change' }]"
            v-if="positionId === 6 || (positionId === 10 && formquery.adStyle === 1)"
            label="模版类型"
            prop="templateTypeId"
          >
            <a-select style="width: 34%" placeholder="请选择模版类型" v-model="formquery.templateTypeId" allowClear>
              <a-select-option v-for="item in templateTypeList" :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 左右边距（账户限制：全有） -->
          <a-form-model-item
            v-if="positionId === 6 || (positionId === 10 && formquery.adStyle === 1)"
            label="左右边距"
            prop="lrMargin"
          >
            <a-input-number style="width: 200px" placeholder="请输入左右边距" v-model.trim="formquery.lrMargin" />
          </a-form-model-item>
          <!-- 上下边距（账户限制：全有） -->
          <a-form-model-item
            v-if="positionId === 6 || (positionId === 10 && formquery.adStyle === 1)"
            label="上下边距"
            prop="tbMargin"
          >
            <a-input-number style="width: 200px" placeholder="请输入上下边距" v-model.trim="formquery.tbMargin" />
          </a-form-model-item>
          <!-- 竞价底价 -->
          <a-form-model-item
            v-if="role === 'SUPER_ADMIN' || +currentLoginId === 25"
            label="竞价底价"
            prop="bidfloor"
          >
            <a-input type="number" v-model.trim="formquery.bidfloor" placeholder="请输入竞价底价" style="width: 200px"></a-input>
          </a-form-model-item>
          <PlatAPISourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
        </a-form-model>
      </div>
    </a-spin>
    <AddAccountModal
      v-bind="$attrs"
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="platInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getPlatAccounts"
      @editAccount="getPlatAccounts(2)"
    />
  </a-modal>
</template>

<script>
import { gettemplateTypeList, updateMediatomAdx } from '@/api/aggregate'
import AddAccountModal from '../addAccountModal'
import TableScreenSourceForm from '@/components/sourceModal/TableScreenSourceForm'
import OpenScreenSourceForm from '@/components/sourceModal/OpenScreenSourceForm'
import OpenOrTableScreenSourceForm from '@/components/sourceModal/OpenOrTableScreenSourceForm'
import IncentiveVideoSourceForm from '@/components/sourceModal/IncentiveVideoSourceForm'
import { mapState } from 'vuex'
import {
  csjadTypeList,
  ylhadTypeList,
  ksadTypeList,
  bddTypeList,
  otadStyleList,
  bdadStyleList,
  ksadStyleList
} from './list'
import getList from '@/mixins/getList'
import HostSpotSourceForm from '@/components/sourceModal/HotSpotSourceForm'
import PlatAPISourceForm from '@/components/sourceModal/PlatAPISourceForm'
export default {
  components: { AddAccountModal, HostSpotSourceForm, TableScreenSourceForm, OpenScreenSourceForm, OpenOrTableScreenSourceForm, PlatAPISourceForm, IncentiveVideoSourceForm },
  mixins: [getList],
  name: 'SourceModal',
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      YLHadCrtTypeListDisabled: false,
      // 优量汇广告场景选择
      sceneKey: 1,
      // 账户绑定参数
      bindquery: {},
      // 广告源参数
      formquery: {
        appId: '',
        platId: '',
        platAccountId: undefined,
        isAutoCreate: 0,
        scene: undefined,
        crtType: undefined,
        adCrtTypeList: [],
        templateIdList: [],
        imgSizeList: [],
        videoSizeList: [],
        countdownShow: 0,
        rewardedType: undefined,
        rewardedNum: 0,
        orientation: 1,
        adRolloutSize: 1,
        skipDuration: 5,
        videoVoiceControl: 1,
        platAppId: '',
        appKey: '',
        name: '',
        platPlaceId: undefined,
        ext: '',
        templateIds: [],
        isAutoDrawing: '0',
        settleType: 0,
        bidfloor: null,
        dealid: '',
        isAutoSorting: '0',
        isBlack: '0',
        floatingRate: 0,
        templateTypeId: undefined,
        lrMargin: 0,
        tbMargin: 0,
        isDownload: 'S',
        os: 2,
        appnameReplace: undefined,
        versionReplace: undefined,
        bundleReplace: undefined,
        planId: undefined,
        adScene: undefined,
        adType: 1,
        adStyle: 1,
        bidfloorStrategy: '0',
        settleStrategy: '0',
        dspSettleStrategy: '0',
        bidfloorFloatingRate: 0,
        settleBidfloor: 1,
        dspFloatingRate: 1,
        isFullScreen: 0,
        cpCloseButton: 'S',
        cpDisplayCountdown: 1,
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      },
      rules: {},
      // 广告平台列表
      platList: [],
      allPlatList: [],
      // 当前广告平台（绑定左侧menu）
      currentPlat: [],
      // 左侧menu搜索字段
      searchKey: '',
      // 当前选择的广告平台
      platInfo: {
        name: '',
        id: '',
        accessType: ''
      },
      // 账户列表
      accountList: [],
      // 添加账户弹窗
      addAccountVisible: false,
      isAddAcount: true,
      // 素材模板列表
      templeteList: [],
      hasGetTemplateList: false,
      // 模板类型列表
      templateTypeList: [],
      // 广告计划列表
      adPlanList: [],
      // 应用ID是否正在修改
      allowEdit: false,
      // 修改之前的应用ID
      editPlatAppId: '',
      adGroupList: [], // 广告组列表
      isLoading: false
    }
  },
  props: {
    // 当前广告位的广告样式ID
    positionId: {
      default: '',
      type: [Number, String]
    },
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    groupName: {
      default: '',
      type: String
    },
    testList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        this.formquery = JSON.parse(JSON.stringify(val))
        this.$set(this.formquery, 'adType', +this.formquery.adType >= 0 ? Number(this.formquery.adType) : undefined)
        this.$set(this.formquery, 'adStyle', +this.formquery.adStyle >= 0 ? Number(this.formquery.adStyle) : undefined)
        // UI组件绑定String格式
        this.formquery.templateIds && (this.formquery.templateIds = this.formquery.templateIds.map((item) => '' + item))
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      currentLoginId: (state) => state.user.id
      // sensitivity: (state) => state.asyncPermission.sensitivity,
    }),
    accountInfo () {
      const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
      return account || null
    },
    adxSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    adTypeList () {
      const adTypeObj = {
        1: csjadTypeList,
        2: ylhadTypeList,
        3: ksadTypeList,
        6: bddTypeList
      }
      return adTypeObj[this.formquery.platId]
    },
    adStyleList () {
      const formquery = this.formquery
      let list = []
      // 穿山甲 广告位类型选择贴片时 ｜｜ 优量汇 广告位类型选择沉浸式视频流时
      const type =
        (formquery.adType === 3 && formquery.platId === 1) || (formquery.adType === 4 && formquery.platId === 2)
      list = type ? (list = otadStyleList.filter((item) => item.id === 2)) : otadStyleList
      // 百度
      if (formquery.platId === 6) {
        list = bdadStyleList
      }
      // 快手
      if (formquery.platId === 3 && formquery.adType === 2) {
        list = ksadStyleList
      }
      return list
    }
  },
  async mounted () {
    this.gettemplateTypeList()
    // this.default()
  },
  methods: {
    changeformquery (e) {
      this.formquery = JSON.parse(JSON.stringify(e))
    },
    // 获取模版类型列表
    async gettemplateTypeList () {
      const resp = await gettemplateTypeList()
      this.templateTypeList = resp.data || []
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleCancel () {
      this.adxSourceVisible = false
    },
    // 确认按钮点击事件
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await updateMediatomAdx({
            ...this.formquery,
            groupId: this.query.groupId,
            testId: this.query.testId
          })
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.adxSourceVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.source_wrapper {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
  background-color: #fff;
  padding: 15px 0 0 0;
}
</style>
