/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-19 16:54:05
 * @FilePath: /apiForManage/financialManagementA.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  // mediatom报表
  adxSettleReport: 'adxSettle/report',
  // mediatom报表详情
  adxSettleReportDetails: 'adxSettle/reportDetails',
  // mediatom报表修改状态
  adxSettleUpdateSts: 'adxSettle/updateSts'
}

export default api

export function adxSettleReport (data) {
  return request({
    url: api.adxSettleReport,
    method: 'post',
    data
  })
}
export function adxSettleReportDetails (data) {
  return request({
    url: api.adxSettleReportDetails,
    method: 'post',
    data
  })
}
export function adxSettleUpdateSts (data) {
  return request({
    url: api.adxSettleUpdateSts,
    method: 'post',
    data
  })
}
