/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-16 18:23:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-14 15:44:33
 * @FilePath: /mediatom-web/src/views/aggregate/RtbConfig/RtbConfigTable/components/sourceModal/list.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const adStyleList = [{
    id: '1',
    name: '模板渲染'
  },
  {
    id: '2',
    name: '自渲染'
  }
]

export const typeList = [{
    id: '1',
    name: '图片'
  },
  {
    id: '2',
    name: 'video'
  }
]

export const slotList1080 = [{
  id: 1,
  name: '信息流图文'
}, {
  id: 5,
  name: '横幅图片'
}, {
  id: 6,
  name: '插屏图片'
}, {
  id: 7,
  name: '开屏图片'
}, {
  id: 8,
  name: '视频前贴'
}, {
  id: 9,
  name: '视频中贴'
}, {
  id: 10,
  name: '视频后贴'
}, {
  id: 11,
  name: '激励视频'
}, {
  id: 12,
  name: '信息流视频'
}, {
  id: 13,
  name: '开屏视频'
}]
