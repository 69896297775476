<template>
  <div>
    <!--
        （原生混合 && adstyle有值 && 不为竖版视频）|| 原生模版
     -->
    <a-form-model-item
      label="样式类型"
      prop="drawTypeList"
      :rules="[{ required: true, message: '请选择样式类型', trigger: 'change' }]"
      v-if="+positionId === 6 || (+positionId === 10 && +formquery.adStyle === 1 && +formquery.adType !== 3)"
    >
      <m-select-more
        style="width: 400px"
        v-model="query.drawTypeList"
        :allData="[
          { name: '左图右文', id: '1' },
          { name: '左文右图', id: '2' },
          { name: '上图下文', id: '3' },
          { name: '上文下图', id: '4' },
          { name: '三图', id: '5' },
          { name: '竖版样式', id: '6' }
        ]"
        :searchById="false"
        :showLabel="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="样式类型"
        @filter="changeFormQuery('drawTypeList', $event)"
        @change="changeFormQuery('drawTypeList', $event)"
      />
    </a-form-model-item>
    <!--
      （原生混合 && adstyle有值）|| 原生自渲染
     -->
    <a-form-model-item
      label="样式类型"
      prop="drawTypeList"
      :rules="[{ required: true, message: '请选择样式类型', trigger: 'change' }]"
      v-if="+positionId === 4 || (+positionId === 10 && +formquery.adStyle === 2)"
    >
      <m-select-more
        style="width: 400px"
        v-model="query.drawTypeList"
        :allData="allDrawTypeList"
        :searchById="false"
        :showLabel="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="样式类型"
        @change="
          ($event) => {
            formRef.clearValidate()
            changeFormQuery('drawTypeList', $event)
          }
        "
        @filter="changeFormQuery('drawTypeList', $event)"
      />
    </a-form-model-item>
    <!--
      (（原生混合 && adstyle有值）|| 原生自渲染) && 样式类型中无图片
     -->
    <a-form-model-item
      label="图片设置"
      prop="imgSizeList"
      :rules="[
        {
          required: true,
          validator: (rule, value, callback) => {
            validatorImgSizeList(rule, value, callback, query.drawTypeList)
          },
          trigger: 'change'
        }
      ]"
      v-if="
        (+positionId === 4 || (+positionId === 10 && +formquery.adStyle === 2)) &&
          (formquery.drawTypeList.includes('33') ||
          formquery.drawTypeList.includes('34') ||
          formquery.drawTypeList.includes('35') ||
          formquery.drawTypeList.includes('36') ||
          formquery.drawTypeList.includes('28') ||
          formquery.drawTypeList.includes('29') ||
          formquery.drawTypeList.includes('30') ||
          formquery.drawTypeList.includes('42'))
      "
    >
      <m-select-more
        style="width: 400px"
        v-model="query.imgSizeList"
        :allData="imgSetList"
        :searchById="false"
        :showLabel="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="图片设置"
        @filter="changeFormQuery('imgSizeList', $event)"
        @change="changeFormQuery('imgSizeList', $event)"
      />
    </a-form-model-item>
    <!--
      (（原生混合 && adstyle有值）|| 原生自渲染) && 样式类型中无视频
     -->
    <a-form-model-item
      label="视频设置"
      prop="videoSizeList"
      :rules="[
        {
          required: true,
          validator: (rule, value, callback) => {
            validatorVideoSizeList(rule, value, callback, query.drawTypeList)
          },
          trigger: 'change'
        }
      ]"
      v-if="
        (+positionId === 4 ||
          (+positionId === 10 &&
          +formquery.adStyle === 2)) && (formquery.drawTypeList.includes('37') || formquery.drawTypeList.includes('41'))
      "
    >
      <m-select-more
        style="width: 400px"
        v-model="query.videoSizeList"
        :allData="videoSetList"
        :searchById="false"
        :showLabel="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="视频设置"
        @filter="changeFormQuery('videoSizeList', $event)"
        @change="changeFormQuery('videoSizeList', $event)"
      />
    </a-form-model-item>
  </div>
</template>

<script>
import { validatorImgSizeList, validatorVideoSizeList } from './sourceValidator'
export default {
  name: 'BaiduAutoCreateForm',
  data () {
    return {
      query: {},
      allDrawTypeList: [
        { name: '单图(左图右文)：图片+标题+描述', id: '33' },
        { name: '单图(右图左文)：图片+标题+描述', id: '34' },
        { name: '三图图文：图片+标题+描述', id: '35' },
        { name: '三图+logo：图片+标题+描述+logo', id: '36' },
        { name: '大图(上图下文)：图片+标题+描述', id: '28' },
        { name: '大图(上文下图)：图片+标题+描述', id: '29' },
        { name: '大图+logo：图片+标题+描述+logo', id: '30' },
        { name: '竖图：图片', id: '42' },
        { name: '视频：视频+标题+描述', id: '37' },
        { name: '竖版视频：视频+标题+描述', id: '41' }
      ]
    }
  },
  props: {
    formquery: {
      default: () => ({}),
      type: Object,
      require: true
    },
    positionId: {
      default: 0,
      type: [Number, String]
    },
    formRef: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    imgSetList () {
      if (!(+this.positionId === 4 || (+this.positionId === 10 && +this.formquery.adStyle === 2))) {
        return []
      }
      let arr = []
      if (this.formquery.drawTypeList.includes('33') || this.formquery.drawTypeList.includes('34')) {
        arr = [...arr, { name: '单图尺寸 2：1（600x300）', id: '1' }, { name: '单图尺寸 3：2（960x640）', id: '2' }]
      }
      if (this.formquery.drawTypeList.includes('35') || this.formquery.drawTypeList.includes('36')) {
        arr = [...arr, { name: '三图尺寸 2：1（600x300）', id: '3' }, { name: '三图尺寸 3：2（960x640）', id: '4' }]
      }
      if (
        this.formquery.drawTypeList.includes('28') ||
        this.formquery.drawTypeList.includes('29') ||
        this.formquery.drawTypeList.includes('30')
      ) {
        arr = [...arr, { name: '大图尺寸 2：1（600x300）', id: '5' }, { name: '大图尺寸16：9（1280x720）', id: '6' }]
      }
      if (this.formquery.drawTypeList.includes('42')) {
        arr = [...arr, { name: '竖图尺寸 2：3（640x960）', id: '7' }, { name: '竖图尺寸 9：16（720x1280）', id: '8' }]
      }
      return arr
    },
    videoSetList () {
      const arr = []
      if (!(+this.positionId === 4 || (+this.positionId === 10 && +this.formquery.adStyle === 2))) {
        return []
      }
      if (this.formquery.drawTypeList.includes('37')) {
        arr.push({
          name: '视频尺寸16：9（1280x720）',
          id: '9'
        })
      }
      if (this.formquery.drawTypeList.includes('41')) {
        arr.push({
          name: '视频尺寸 9：16（720x1280）',
          id: '10'
        })
      }
      return arr
    }
  },
  watch: {
    formquery: {
      handler (val) {
        this.query = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeFormQuery (prop, value) {
      this.$emit('changeFormQuery', prop, value)
    },
    validatorImgSizeList,
    validatorVideoSizeList
  }
}
</script>

<style>
</style>
