var render = function render(){var _vm=this,_c=_vm._self._c;return (+_vm.positionId === 5 && (+_vm.platInfo.accessType === 1 || +_vm.platInfo.id === 1019))?_c('div',[_c('a-form-model-item',{attrs:{"prop":"drawType","label":"激励视频显示"}},[_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.drawType),callback:function ($$v) {_vm.$set(_vm.formquery, "drawType", $$v)},expression:"formquery.drawType"}},[_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 竖屏 ")]),_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 横屏 ")])],1)],1),(_vm.isAdmin)?[_c('a-form-model-item',{attrs:{"prop":"rdCountdown"}},[_c('m-tips',{attrs:{"slot":"label","content":`控制图片渲染成激励视频类型的广告，总倒计时时长。`,"title":'倒计时时长',"width":200},slot:"label"}),_c('a-input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入倒计时时长"},on:{"input":_vm.changeRdCountdown},model:{value:(_vm.formquery.rdCountdown),callback:function ($$v) {_vm.$set(_vm.formquery, "rdCountdown", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.rdCountdown"}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("s")])])],1),_c('a-form-model-item',{attrs:{"prop":"rdSkipTime"}},[_c('m-tips',{attrs:{"slot":"label","content":`显示跳过按钮的时间，如不设置，不显示跳过按钮。`,"title":'n秒显示跳过',"width":200},slot:"label"}),_c('a-input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入n秒显示跳过"},on:{"input":_vm.changeRdSkipTime},model:{value:(_vm.formquery.rdSkipTime),callback:function ($$v) {_vm.$set(_vm.formquery, "rdSkipTime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.rdSkipTime"}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("s")])])],1),(_vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdType"}},[_c('m-tips',{attrs:{"slot":"label","content":`控制奖励发放的方式<br/>
点击后发放：<br/>
设置后会在指定时间出现“点击后立刻获取奖励”提示。出现比例用于控制该功能生效的比例。<br/>
自动发放：<br/>
视频播放完成或播放到指定时间发放奖励。如未设置，按自动发放处理。`,"title":'发放方式',"width":200},slot:"label"}),_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.rdType),callback:function ($$v) {_vm.$set(_vm.formquery, "rdType", $$v)},expression:"formquery.rdType"}},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 点击后发放 ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 自动发放 ")])],1)],1):_vm._e(),(_vm.formquery.rdType === 0 && _vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdClickTime","label":"出现时间"}},[_c('a-input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入出现时间"},on:{"input":_vm.changeRdClickTime},model:{value:(_vm.formquery.rdClickTime),callback:function ($$v) {_vm.$set(_vm.formquery, "rdClickTime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.rdClickTime"}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("s")])])],1):_vm._e(),(_vm.formquery.rdType === 0 && _vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdClickRatio","label":"出现比例"}},[_c('a-input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入出现比例"},on:{"input":_vm.changeRdClickRatio},model:{value:(_vm.formquery.rdClickRatio),callback:function ($$v) {_vm.$set(_vm.formquery, "rdClickRatio", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.rdClickRatio"}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("%")])])],1):_vm._e(),(_vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdAutoType"}},[_c('m-tips',{attrs:{"slot":"label","content":`控制奖励发放的时间节点<br/>
定时发放：<br/>
播放到设置的时间发放奖励。<br/>
播放完发放：<br/>
视频播放完成发放奖励。`,"title":'自动发放奖励类型',"width":200},slot:"label"}),_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.rdAutoType),callback:function ($$v) {_vm.$set(_vm.formquery, "rdAutoType", $$v)},expression:"formquery.rdAutoType"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 定时发放 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 播放完发放 ")])],1)],1):_vm._e(),(_vm.formquery.rdAutoType === 1 && _vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdAutoTime","label":"定时发放时间"}},[_c('a-input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入定时发放时间"},on:{"input":_vm.changeRdAutoTime},model:{value:(_vm.formquery.rdAutoTime),callback:function ($$v) {_vm.$set(_vm.formquery, "rdAutoTime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.rdAutoTime"}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("s")])])],1):_vm._e(),(_vm.isAdmin)?_c('a-form-model-item',{attrs:{"prop":"rdAutoskipSts"}},[_c('m-tips',{attrs:{"slot":"label","content":`发放奖励前5秒，倒计时提示用户自动打开落地页，发放奖励同时自动点击广告。用户可以手动取消此功能。`,"title":'自动跳转',"width":200},slot:"label"}),_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.rdAutoskipSts),callback:function ($$v) {_vm.$set(_vm.formquery, "rdAutoskipSts", $$v)},expression:"formquery.rdAutoskipSts"}},[_c('a-radio',{attrs:{"value":"A"}},[_vm._v(" 开启 ")]),_c('a-radio',{attrs:{"value":"S"}},[_vm._v(" 关闭 ")])],1)],1):_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }