<template>
  <div class="suggested-container">
    <div v-if="suggest.length > maxLen" class="suggested-long">
      <span v-if="!isOpen">
        {{ suggest.substring(0, maxLen) + '...' }}
        <span class="open_btn" @click="isOpen = !isOpen">展开全部</span>
      </span>
      <span v-else>
        {{ suggest }}
        <span class="open_btn" @click="isOpen = !isOpen">收起</span>
      </span>
    </div>
    <span v-else class="suggested-short">{{ suggest || '--' }}</span>
  </div>
</template>

<script>
export default {
  props: {
    suggest: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      isOpen: false,
      maxLen: 40
    }
  }
}
</script>

<style lang="less" scoped>
.suggested-container {
  text-align: left;
  .open_btn {
    color: @primary-color;
    cursor: pointer;
  }
}
</style>
