/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-26 15:02:42
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-26 15:03:17
 * @FilePath: /mediatom-web/src/utils/showIcon.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 是否展示自动创建icon
export function getShowAutoIcon (platId) {
  const p = +platId
  //  穿山甲、优量汇、快手
  if (p === 1 || p === 2 || p === 3 || p === 6) {
    return true
  }
}
export function getShowBiddingIcon (platId) {
  const p = +platId
  //  优量汇、快手、百度、Sigmob、Mintegral、趣盟、VIVO、OPPO、Gromore、沧渊
  if (p === 2 || p === 3 || p === 6 || p === 7 || p === 11 || p === 10 || p === 19 || p === 14 || p === 8 || p === 5) {
    return true
  }
}
