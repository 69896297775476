<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-09 17:52:30
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 18:14:59
 * @FilePath: /mediatom-web/src/views/aggregate/Config/components/sourceModal/KuaishouForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="kuaishou-form-container">
    <a-form-model-item prop="enableKuaishouParam" label="是否启用快手参数">
      <a-switch
        :checked="!!+formquery.enableKuaishouParam"
        size="small"
        @change="handleChangeEnableKuaishouParam"
      ></a-switch>
    </a-form-model-item>
    <template v-if="+formquery.enableKuaishouParam">
      <a-form-model-item prop="kuaishouShakeSensitivity" label="快手摇一摇灵敏度">
        <a-input
          type="number"
          placeholder="请输入快手摇一摇灵敏度"
          v-model.trim="formquery.kuaishouShakeSensitivity"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="kuaishouTwistSensitivity" label="快手扭一扭灵敏度">
        <a-input
          type="number"
          placeholder="请输入快手扭一扭灵敏度"
          v-model.trim="formquery.kuaishouTwistSensitivity"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="kuaishouReplaceParamSettings" label="快手替换参数设置">
        <a-switch
          :checked="!!+formquery.kuaishouReplaceParamSettings"
          size="small"
          @change="handleChangeKuaishouReplaceParamSettings"
        ></a-switch>
      </a-form-model-item>
      <template v-if="+formquery.kuaishouReplaceParamSettings">
        <a-form-model-item prop="kuaishouAppName" label="快手应用名">
          <a-input placeholder="请输入快手应用名" v-model.trim="formquery.kuaishouAppName"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="kuaishouPackageName" label="快手包名">
          <a-input placeholder="请输入快手包名" v-model.trim="formquery.kuaishouPackageName"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="kuaishouSha1" label="快手sha1">
          <a-input placeholder="请输入快手sha1" v-model.trim="formquery.kuaishouSha1"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="kuaishouVersion" label="快手版本号">
          <a-input placeholder="请输入快手版本号" v-model.trim="formquery.kuaishouVersion"></a-input>
        </a-form-model-item>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import mixDate from '@/mixins/initDate'
export default {
  name: 'AdxFlowForm',
  mixins: [mixDate],
  data () {
    return {}
  },
  created () {
    this.$set(this.formquery, 'enableKuaishouParam', this.value.enableKuaishouParam || 0)
    this.$set(this.formquery, 'kuaishouShakeSensitivity', this.value.kuaishouShakeSensitivity || undefined)
    this.$set(this.formquery, 'kuaishouTwistSensitivity', this.value.kuaishouTwistSensitivity || undefined)
    this.$set(this.formquery, 'kuaishouReplaceParamSettings', this.value.kuaishouReplaceParamSettings || 0)
    this.$set(this.formquery, 'kuaishouAppName', this.value.kuaishouAppName || undefined)
    this.$set(this.formquery, 'kuaishouPackageName', this.value.kuaishouPackageName || undefined)
    this.$set(this.formquery, 'kuaishouSha1', this.value.kuaishouSha1 || undefined)
    this.$set(this.formquery, 'kuaishouVersion', this.value.kuaishouVersion || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    moment,
    handleChangeKuaishouReplaceParamSettings (e) {
      this.formquery.kuaishouReplaceParamSettings = e ? 1 : 0
    },
    handleChangeEnableKuaishouParam (e) {
      this.formquery.enableKuaishouParam = e ? 1 : 0
    }
  }
}
</script>

<style lang="less" scoped>
// .kuaishou-form-container{
//   border: 1px solid;
// }
</style>
