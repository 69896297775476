var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(+_vm.positionId === 6 || (+_vm.positionId === 10 && +_vm.formquery.adStyle === 1 && +_vm.formquery.adType !== 3))?_c('a-form-model-item',{attrs:{"label":"样式类型","prop":"drawTypeList","rules":[{ required: true, message: '请选择样式类型', trigger: 'change' }]}},[_c('m-select-more',{staticStyle:{"width":"400px"},attrs:{"allData":[
        { name: '左图右文', id: '1' },
        { name: '左文右图', id: '2' },
        { name: '上图下文', id: '3' },
        { name: '上文下图', id: '4' },
        { name: '三图', id: '5' },
        { name: '竖版样式', id: '6' }
      ],"searchById":false,"showLabel":false,"hasIcon":false,"showId":false,"width":"450px","label":"样式类型"},on:{"filter":function($event){return _vm.changeFormQuery('drawTypeList', $event)},"change":function($event){return _vm.changeFormQuery('drawTypeList', $event)}},model:{value:(_vm.query.drawTypeList),callback:function ($$v) {_vm.$set(_vm.query, "drawTypeList", $$v)},expression:"query.drawTypeList"}})],1):_vm._e(),(+_vm.positionId === 4 || (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2))?_c('a-form-model-item',{attrs:{"label":"样式类型","prop":"drawTypeList","rules":[{ required: true, message: '请选择样式类型', trigger: 'change' }]}},[_c('m-select-more',{staticStyle:{"width":"400px"},attrs:{"allData":_vm.allDrawTypeList,"searchById":false,"showLabel":false,"hasIcon":false,"showId":false,"width":"450px","label":"样式类型"},on:{"change":($event) => {
          _vm.formRef.clearValidate()
          _vm.changeFormQuery('drawTypeList', $event)
        },"filter":function($event){return _vm.changeFormQuery('drawTypeList', $event)}},model:{value:(_vm.query.drawTypeList),callback:function ($$v) {_vm.$set(_vm.query, "drawTypeList", $$v)},expression:"query.drawTypeList"}})],1):_vm._e(),(
      (+_vm.positionId === 4 || (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2)) &&
        (_vm.formquery.drawTypeList.includes('33') ||
        _vm.formquery.drawTypeList.includes('34') ||
        _vm.formquery.drawTypeList.includes('35') ||
        _vm.formquery.drawTypeList.includes('36') ||
        _vm.formquery.drawTypeList.includes('28') ||
        _vm.formquery.drawTypeList.includes('29') ||
        _vm.formquery.drawTypeList.includes('30') ||
        _vm.formquery.drawTypeList.includes('42'))
    )?_c('a-form-model-item',{attrs:{"label":"图片设置","prop":"imgSizeList","rules":[
      {
        required: true,
        validator: (rule, value, callback) => {
          _vm.validatorImgSizeList(rule, value, callback, _vm.query.drawTypeList)
        },
        trigger: 'change'
      }
    ]}},[_c('m-select-more',{staticStyle:{"width":"400px"},attrs:{"allData":_vm.imgSetList,"searchById":false,"showLabel":false,"hasIcon":false,"showId":false,"width":"450px","label":"图片设置"},on:{"filter":function($event){return _vm.changeFormQuery('imgSizeList', $event)},"change":function($event){return _vm.changeFormQuery('imgSizeList', $event)}},model:{value:(_vm.query.imgSizeList),callback:function ($$v) {_vm.$set(_vm.query, "imgSizeList", $$v)},expression:"query.imgSizeList"}})],1):_vm._e(),(
      (+_vm.positionId === 4 ||
        (+_vm.positionId === 10 &&
        +_vm.formquery.adStyle === 2)) && (_vm.formquery.drawTypeList.includes('37') || _vm.formquery.drawTypeList.includes('41'))
    )?_c('a-form-model-item',{attrs:{"label":"视频设置","prop":"videoSizeList","rules":[
      {
        required: true,
        validator: (rule, value, callback) => {
          _vm.validatorVideoSizeList(rule, value, callback, _vm.query.drawTypeList)
        },
        trigger: 'change'
      }
    ]}},[_c('m-select-more',{staticStyle:{"width":"400px"},attrs:{"allData":_vm.videoSetList,"searchById":false,"showLabel":false,"hasIcon":false,"showId":false,"width":"450px","label":"视频设置"},on:{"filter":function($event){return _vm.changeFormQuery('videoSizeList', $event)},"change":function($event){return _vm.changeFormQuery('videoSizeList', $event)}},model:{value:(_vm.query.videoSizeList),callback:function ($$v) {_vm.$set(_vm.query, "videoSizeList", $$v)},expression:"query.videoSizeList"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }