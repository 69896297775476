export function validatorImgSizeList (rule, value, callback, drawTypeList) {
  // 单图
  if (drawTypeList.find((item) => ['33', '34'].includes(item)) && (!value.includes('1') && !value.includes('2'))) {
    return callback(new Error('至少选择一个单图'))
  }
  // 三图
  if (drawTypeList.find((item) => ['35', '36'].includes(item)) && (!value.includes('3') && !value.includes('4'))) {
    return callback(new Error('至少选择一个三图'))
  }
  // 大图
  if (drawTypeList.find((item) => ['28', '29', '30'].includes(item)) && (!value.includes('5') && !value.includes('6'))) {
    return callback(new Error('至少选择一个大图'))
  }
  // 竖图
  if (drawTypeList.find((item) => ['42'].includes(item)) && (!value.includes('8') && !value.includes('7'))) {
    return callback(new Error('至少选择一个竖图'))
  }
  return callback()
}

export function validatorVideoSizeList (rule, value, callback, drawTypeList) {
  // 普通视频
  if (drawTypeList.includes('37') && !value.includes('9')) {
    return callback(new Error('请选择视频尺寸16：9'))
  }
  // 竖版视频
  if (drawTypeList.includes('41') && !value.includes('10')) {
    return callback(new Error('请选择视频尺寸9：16'))
  }
  return callback()
}
