<template>
  <a-modal
    v-model="addGroupVisible"
    :title="(isAdd ? '添加' : '修改') + '流量分组'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="880"
    dialogClass="group_modal"
  >
    <template slot="footer">
      <a-button
        v-if="origin === 'https://app.mediatom.cn'"
        style="margin-right: 550px"
        type="link"
        target="_blank"
        href="https://app.mediatom.cn/docs/#/?blogid=128"
      >如何配置规则?</a-button
      >
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleSubmit">确定</a-button>
    </template>

    <div class="form_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <a-form-model-item
          has-feedback
          label="流量分组名称"
          prop="name"
          :rules="[{ required: true, message: '请输入流量分组名称', trigger: 'blur' }]"
        >
          <a-input
            :disabled="isDefault"
            style="width: 88%"
            v-model.trim="formquery.name"
            autocomplete="off"
            placeholder="请输入流量分组名称"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="groupId"
          v-if="isAdd"
        >
          <m-tips
            slot="label"
            content="如果选择了复制某一流量分组，则会将该分组下的分组规则、AB测试策略、广告源配置策略以及高级配置中的瀑布流策略都同步至新的流量分组。"
            :title="'复制分组'"
            :width="200"
          />
          <a-select allowClear style="width: 300px" v-model="formquery.groupId" placeholder="请选择复制分组" @change="handleChangeGroupId">
            <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="!isDefault" class="form_item" :wrapperCol="{ span: 24 }">
          <!-- <div style="border-bottom: 1px solid #eee"></div> -->
          <a-divider>流量规则</a-divider>
        </a-form-model-item>
        <!-- SDK版本 -->
        <a-form-model-item class="form_item" label="SDK版本" v-if="showLog.sdkShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="sdkSelect">
                <a-select v-model="formquery.sdkSelect">
                  <a-select-option v-for="item in options4" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="sdk"
                :rules="[{ required: true, message: '请输入SDK版本', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="示例：5.0.0，可填写多个版本号，用中文或英文逗号隔开"
                  v-model.trim="formquery.sdk"
                  autocomplete="off"
                  @input="handleInputSDK"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(2)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <!-- 设备ID -->
        <a-form-model-item class="form_item" label="设备ID" v-if="showLog.equipmentShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="equipSelect">
                <a-select v-model="formquery.equipSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="equipVal"
                :rules="[{ required: true, message: '请输入设备ID', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个设备ID，用中文或英文逗号隔开"
                  v-model.trim="formquery.equipVal"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(3)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 渠道 -->
        <a-form-model-item class="form_item" label="渠道" v-if="showLog.channelShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="channelSelect">
                <a-select v-model="formquery.channelSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="channelVal"
                :rules="[{ required: true, message: '请输入渠道', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个渠道，用中文或英文逗号隔开"
                  v-model.trim="formquery.channelVal"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(1)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 国家 -->
        <a-form-model-item class="form_item" label="国家/地区" v-if="showLog.areaShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="areaSelect">
                <a-select v-model="formquery.areaSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="areaVal"
                :rules="[{ required: true, message: '请选择国家/地区', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 100%"
                  class="search_item"
                  v-model="formquery.areaVal"
                  :allData="countryList"
                  :searchById="false"
                  :hasIcon="false"
                  :showId="false"
                  label="国家/地区"
                  :showLabel="false"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(17)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <!-- 省份 -->
        <a-form-model-item class="form_item" label="省份" v-if="hasChina && showLog.areaShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="chinaSelect">
                <a-select v-model="formquery.chinaSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="chinaVal"
                :rules="[{ required: true, message: '请选择省份', trigger: 'change' }]"
              >
                <el-cascader
                  style="width: 100%"
                  v-model="formquery.chinaVal"
                  placeholder="请选择省份"
                  collapse-tags
                  :options="areaList"
                  :props="{
                    multiple: true
                  }"
                />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(4)"><a-icon type="close-circle" /></a-button>
            </a-col> -->
          </a-row>
        </a-form-model-item>

        <!-- 日期 -->
        <a-form-model-item class="form_item" label="日期" v-if="showLog.dateShow && !isDefault">
          <a-row type="flex">
            <a-col :span="21" v-for="(item, index) in formquery.dateVal" :key="index" style="justify-content: start">
              <a-select
                v-model="formquery.dateSelect"
                :disabled="index !== 0"
                style="width: 28%; display: inline-block"
              >
                <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">{{
                  child.label
                }}</a-select-option>
              </a-select>
              <a-range-picker
                style="display: inline-block; width: 62%"
                format="YYYY-MM-DD"
                :placeholder="['Start', 'End']"
                v-model="formquery.dateVal[index]"
                :allowClear="false"
              />
              <span v-if="index !== 0">
                <a-icon
                  @click="handleCloseDateItem(index)"
                  style="color: red; fon-size: 12px; margin-left: 10px; cursor: pointer"
                  type="close-circle"
                /></span>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(5)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
          <a-button type="link" @click="handleAddDateItem"><a-icon type="plus" />添加日期规则</a-button>
        </a-form-model-item>

        <!-- 时间 -->
        <a-form-model-item class="form_item" label="时间" v-if="showLog.timeShow && !isDefault">
          <a-row type="flex">
            <a-col :span="21" v-for="(item, index) in formquery.timeVal" :key="index" style="justify-content: start">
              <a-select
                v-model="formquery.timeSelect"
                :disabled="index !== 0"
                style="width: 28%; display: inline-block"
              >
                <a-select-option v-for="child in options1" :key="child.value" :value="child.value">{{
                  child.label
                }}</a-select-option>
              </a-select>
              <a-time-picker
                style="margin-right: 12px; width: 27%"
                v-model="formquery.timeVal[index][0]"
                format="HH:mm"
              />
              <span>至</span>
              <a-time-picker
                style="margin-left: 12px; width: 27%"
                v-model="formquery.timeVal[index][1]"
                format="HH:mm"
                clearText
              />
              <span v-if="index !== 0">
                <a-icon
                  @click="handleCloseTimeItem(index)"
                  style="color: red; fon-size: 12px; margin-left: 10px; cursor: pointer"
                  type="close-circle"
                /></span>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(6)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
          <a-button type="link" @click="handleAddTimeItem"><a-icon type="plus" />添加时间规则</a-button>
        </a-form-model-item>

        <!-- 设备品牌 -->
        <a-form-model-item class="form_item" label="设备品牌" v-if="showLog.brandShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="brandSelect">
                <a-select v-model="formquery.brandSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="brandVal"
                :rules="[{ required: true, message: '请选择设备品牌', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 100%"
                  class="search_item"
                  v-model="formquery.brandVal"
                  :allData="brandList"
                  :searchById="false"
                  :hasIcon="false"
                  :showId="false"
                  label="设备品牌"
                  :showLabel="false"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(7)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 网络类型 -->
        <a-form-model-item class="form_item" label="网络类型" v-if="showLog.nettypeShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="nettypeSelect">
                <a-select v-model="formquery.nettypeSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="nettypeVal"
                :rules="[{ required: true, message: '请选择网络类型', trigger: 'change' }]"
              >
                <a-select placeholder="请选择网络类型" :maxTagCount="3" mode="multiple" v-model="formquery.nettypeVal">
                  <a-select-option v-for="item in netTypeList" :key="item" :value="item">{{ item }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(8)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 系统版本 -->
        <a-form-model-item class="form_item" label="系统版本" v-if="showLog.sysVersionShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="sysVersionSelect">
                <a-select v-model="formquery.sysVersionSelect">
                  <a-select-option v-for="item in options4" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="sysVersion"
                :rules="[{ required: true, message: '请输入系统版本', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个系统版本，用中文或英文逗号隔开"
                  v-model.trim="formquery.sysVersion"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(9)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
        <!-- 应用版本 -->
        <a-form-model-item class="form_item" label="应用版本" v-if="showLog.appVersionShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="appVersionSelect">
                <a-select v-model="formquery.appVersionSelect">
                  <a-select-option v-for="item in options4" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="appVersion"
                :rules="[{ required: true, message: '请输入应用版本', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个应用版本，用中文或英文逗号隔开"
                  v-model.trim="formquery.appVersion"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(10)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 子渠道 -->
        <a-form-model-item class="form_item" label="子渠道" v-if="showLog.childchannelShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="childchannelSelect">
                <a-select v-model="formquery.childchannelSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="childchannelVal"
                :rules="[{ required: true, message: '请输入子渠道', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个子渠道，用中文或英文逗号隔开"
                  v-model.trim="formquery.childchannelVal"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(11)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 自定义规则 -->
        <a-form-model-item class="form_item" v-if="showLog.custShow && !isDefault">
          <div style="display: inline-block" slot="label">
            自定义规则<m-tips
              :width="300"
              content="通过Key-Value条件进行设置，Key和Value的条件可以是等于=或不等于!=，一个Key可以对应多个Value，用英文逗号分隔。多个条件之间使用&，即多个条件需要同时满足。建议Value使用以下字符规则：大小写字母数字和下划线[A-Za-z0-9]"
            />
          </div>
          <a-row type="flex">
            <a-col :span="21">
              <a-form-model-item
                has-feedback
                prop="custVal"
                :rules="[{ required: true, message: '请输入自定义规则', trigger: 'blur' }]"
              >
                <a-input allowClear placeholder="请输入自定义规则" v-model.trim="formquery.custVal" autocomplete="off" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(12)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 应用安装时间 -->
        <a-form-model-item class="form_item" v-if="showLog.installTimeShow && !isDefault">
          <div style="display: inline-block" slot="label">应用安装时间<m-tips content="应用安装时间" /></div>
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="installTimeSelect">
                <a-select v-model="formquery.installTimeSelect">
                  <a-select-option v-for="item in options2Symb" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                has-feedback
                prop="installTime"
                :rules="[{ required: true, message: '请输入应用安装时间', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="请输入应用安装时间"
                  v-model.trim="formquery.installTime"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item has-feedback prop="installTimeType">
                <a-select v-model="formquery.installTimeType">
                  <a-select-option value="d">天</a-select-option>
                  <a-select-option value="h">小时</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(13)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 设备型号 -->
        <a-form-model-item class="form_item" label="设备型号" v-if="showLog.unitTypeShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="unitTypeSelect">
                <a-select v-model="formquery.unitTypeSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="unitTypeVal"
                :rules="[{ required: true, message: '请输入设备型号', trigger: 'blur' }]"
              >
                <a-input
                  allowClear
                  placeholder="可填写多个设备型号，用中文或英文逗号隔开"
                  v-model.trim="formquery.unitTypeVal"
                  autocomplete="off"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(14)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 运营商 -->
        <a-form-model-item class="form_item" label="运营商" v-if="showLog.operatorShow && !isDefault">
          <a-row type="flex">
            <a-col :span="6">
              <a-form-model-item has-feedback prop="operatorSelect">
                <a-select v-model="formquery.operatorSelect">
                  <a-select-option v-for="item in optionsStr2" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="15">
              <a-form-model-item
                has-feedback
                prop="operatorVal"
                :rules="[{ required: true, message: '请选择运营商', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 100%"
                  class="search_item"
                  v-model="formquery.operatorVal"
                  :allData="operatorList"
                  :searchById="false"
                  :hasIcon="false"
                  :showId="false"
                  label="运营商"
                  :showLabel="false"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-button type="link" @click="handleDeleteRuleItem(16)"><a-icon type="close-circle" /></a-button>
            </a-col>
          </a-row>
        </a-form-model-item>

        <!-- 添加规则 -->
        <a-form-model-item :wrapperCol="{ push: 3 }" v-if="!isDefault">
          <a-popover
            ref="popover"
            placement="rightTop"
            trigger="click"
            arrowPointAtCenter
            :overlayStyle="{ width: '420px' }"
          >
            <a-button type="cust"><a-icon type="info" />新增规则<a-icon type="right" /></a-button>
            <template #content>
              <div class="rules_wrapper">
                <ul v-for="(item, index) in groupRuleTypes" :key="index">
                  <template v-for="(child, c) in groupRules">
                    <li
                      :class="{ hasChecked: showLog[child.showKey], noChecked: !showLog[child.showKey] }"
                      :key="c"
                      v-if="child.type === item"
                      @click="handleClickRuleItemShow(child.showKey)"
                    >
                      {{ child.label }}
                    </li>
                  </template>
                </ul>
              </div>
            </template>
          </a-popover>
          <span style="margin: 0 10px">或</span>
          <a-dropdown placement="bottomLeft" :trigger="['click']">
            <a-button style="margin-left: 8px"> 复制已有规则 <a-icon type="down" /> </a-button>
            <a-menu slot="overlay" style="height: 320px; overflow: auto">
              <a-menu-item
                v-for="(item, index) in TemplateList"
                :key="index"
                @click="handleClickTemplete(item)"
              >{{ item.name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" class="form_item" :wrapperCol="{ span: 24 }">
          <!-- <div style="border-bottom: 1px solid #eee"></div> -->
          <a-divider>频次控制</a-divider>
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" class="form_item" label="单设备展示上限（小时）" prop="impressFreqHour">
          <a-input
            @change="handleInputProp($event, 'impressFreqHour')"
            :min="1"
            placeholder="不限"
            style="width: 540px"
            v-model.trim="formquery.impressFreqHour"
          />
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" class="form_item" label="单设备展示上限（天）" prop="impressFreqDay">
          <a-input
            @change="handleInputProp($event, 'impressFreqDay')"
            :min="1"
            placeholder="不限"
            style="width: 540px"
            v-model="formquery.impressFreqDay"
          />
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" class="form_item" label="单设备展示时隔（秒）" prop="impressInterval">
          <a-input
            @change="handleInputProp($event, 'impressInterval')"
            :min="1"
            placeholder="不限"
            style="width: 540px"
            v-model.trim="formquery.impressInterval"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { addGroup, updateGroup, updateImpress, getTemplateList } from '@/api/aggregate'
export default {
  name: 'GroupModal',
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      },
      showLog: {
        sdkShow: false, // sdk
        equipmentShow: false, // 设备id
        channelShow: false, // 渠道
        areaShow: false, // 国家
        chinaShow: false, // 中国省份
        dateShow: false, // 日期
        timeShow: false, // 时间
        brandShow: false, // 设备品牌
        operatorShow: false, // 运营商
        nettypeShow: false, // 网络类型
        sysVersionShow: false, // 系统版本
        appVersionShow: false, // APP版本
        childchannelShow: false, // 子渠道
        custShow: false, // 自定义
        installTimeShow: false, // 应用安装时间
        unitTypeShow: false // 设备型号
      },
      formquery: {
        // value
        name: '', // 流量分组名称
        sdk: '', // sdk
        equipVal: '', // 设备ID
        channelVal: '', // 渠道
        areaVal: [], // 国家
        chinaVal: [], // 中国地区
        dateVal: [[]], // 日期
        timeVal: [[null, null]], // 时间
        brandVal: [], // 设备品牌
        nettypeVal: [], // 网络类型
        sysVersion: '', // 系统版本
        appVersion: '', // app版本
        childchannelVal: '', // 子渠道
        custVal: '', // 自定义
        installTime: '', // 安装时间
        installTimeType: 'h', // 安装时间种类
        unitTypeVal: '', // 设备型号
        // type
        sdkSelect: 0, // sdk选择方式
        equipSelect: 0, // 设备ID选择方式
        channelSelect: 0, // 渠道选择方式
        areaSelect: 0, // 国家选择方式
        chinaSelect: 0, // 中国地区
        dateSelect: 0, // 日期选择方式
        timeSelect: 0, // 时间选择方式
        brandSelect: 0, // 设备品牌选择方式
        nettypeSelect: 0, // 网络类型选择方式
        sysVersionSelect: 0, // 系统版本选择方式
        appVersionSelect: 0, // app版本选择方式
        childchannelSelect: 0, // 子渠道选择方式
        custSelect: 0, // 自定义选择方式
        installTimeSelect: 2, // 应用安装时间选择方式
        unitTypeSelect: 0, // 设备型号选择方式
        operatorSelect: 0, // 运营商选择方式
        impressFreqHour: undefined,
        impressFreqDay: undefined,
        impressInterval: undefined
      },
      // 规则分类
      groupRuleTypes: ['time', 'app', 'equipment', 'channel'],
      // 规则详情
      groupRules: [
        { label: 'SDK版本', id: 2, type: 'app', showKey: 'sdkShow' },
        { label: '设备ID', id: 3, type: 'equipment', showKey: 'equipmentShow' },
        { label: '渠道', id: 1, type: 'channel', showKey: 'channelShow' },
        { label: '国家/地区', id: 17, type: 'time', showKey: 'areaShow' },
        { label: '日期', id: 5, type: 'time', showKey: 'dateShow' },
        { label: '时间', id: 6, type: 'time', showKey: 'timeShow' },
        { label: '设备品牌', id: 7, type: 'equipment', showKey: 'brandShow' },
        { label: '网络类型', id: 8, type: 'equipment', showKey: 'nettypeShow' },
        { label: '系统版本', id: 9, type: 'equipment', showKey: 'sysVersionShow' },
        { label: '应用版本', id: 10, type: 'app', showKey: 'appVersionShow' },
        { label: '子渠道', id: 11, type: 'channel', showKey: 'childchannelShow' },
        { label: '自定义规则', id: 12, type: 'channel', showKey: 'custShow' },
        { label: '应用安装时间', id: 13, type: 'app', showKey: 'installTimeShow' },
        { label: '设备型号', id: 14, type: 'equipment', showKey: 'unitTypeShow' },
        { label: '运营商', id: 16, type: 'equipment', showKey: 'operatorShow' }
      ],
      options4: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        },
        {
          value: 2,
          label: '>'
        },
        {
          value: 3,
          label: '<'
        }
      ],
      options1: [
        {
          value: 0,
          label: '包括'
        }
      ],
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ],
      options2Symb: [
        {
          value: 2,
          label: '>'
        },
        {
          value: 3,
          label: '<'
        }
      ],
      netTypeList: ['WIFI', '2G', '3G', '4G', '5G'],
      TemplateList: [],
      rules: {
        // os: [{ required: true, message: '请选择系统平台', trigger: 'change' }],
        // name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        // bundle: [{ required: true, message: '请输入应用包名', trigger: 'blur' }]
      },
      operatorList: [
        { name: '中国移动', id: '1' },
        { name: '中国联通', id: '2' },
        { name: '中国电信', id: '3' },
        { name: '其他', id: '0' }
      ]
    }
  },
  props: {
    areaList: {
      default: () => [],
      type: Array
    },
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    groupInfo: {
      default: () => ({}),
      type: Object
    },
    placeId: {
      default: '',
      type: String
    },
    brandList: {
      default: () => [],
      type: Array
    },
    groupList: {
      default: () => ([]),
      type: Array
    },
    countryList: {
      default: () => ([]),
      type: Array
    }
  },
  watch: {
    groupInfo: {
      async handler (val) {
        if (this.isAdd) return
        this.formquery.name = val.name
        this.formquery.impressFreqHour = val.impressFreqHour
        this.formquery.impressFreqDay = val.impressFreqDay
        this.formquery.impressInterval = val.impressInterval
        val.segmentRule.forEach((item) => {
          this.handlerEditInfo(item)
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    addGroupVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    // 判断是否是默认分组，默认分组不允许编辑规则
    isDefault () {
      return this.groupInfo.id === 'default' && !this.isAdd
    },
    ...mapState({
      origin: (state) => state.autoweb.origin
    }),
    hasChina () {
      if (!this.formquery.areaVal) return false
      return this.formquery.areaVal.includes('CHN')
    }
  },
  mounted () {
    this.getTemplateList()
  },
  methods: {
    handleChangeGroupId (e) {
      if (!e) {
        this.$set(this.formquery, 'groupId', undefined)
        return
      }
      this.$set(this.formquery, 'groupId', e)
      let group = this.groupList.find((item) => item.id === e)
      group = JSON.parse(JSON.stringify(group))
      group.name = this.formquery.name
      this.handleClickTemplete(group)
    },
    handleInputSDK (e) {
      // sdk版本只能输入    数字 . , ，
      this.formquery.sdk = e.target.value.replace(/[^,^，^.^\d]/g, '')
    },
    handleInputProp (e, prop) {
      if (+e.target.value < 1) {
        this.formquery[prop] = undefined
      } else if (+e.target.value >= 1) {
        this.formquery[prop] = Math.ceil(+e.target.value)
      } else {
        this.formquery[prop] = undefined
      }
    },
    // 获取规则模板
    async getTemplateList () {
      const resp = await getTemplateList()
      this.TemplateList = resp.data || []
    },
    // 点击规则模板
    handleClickTemplete (item) {
      // 隐藏当前所有规则
      for (const key in this.showLog) {
        this.showLog[key] = false
      }
      const groupId = this.formquery.groupId
      // 初始化表单
      this.formquery = {
        // value
        name: '', // 流量分组名称
        sdk: '', // sdk
        equipVal: '', // 设备ID
        channelVal: '', // 渠道
        areaVal: [], // 国家
        chinaVal: [], // 中国省份
        dateVal: [[]], // 日期
        timeVal: [[null, null]], // 时间
        brandVal: [], // 设备品牌
        operatorVal: [], // 运营商
        nettypeVal: [], // 网络类型
        sysVersion: '', // 系统版本
        appVersion: '', // app版本
        childchannelVal: '', // 子渠道
        custVal: '', // 自定义
        installTime: '', // 安装时间
        installTimeType: 'h', // 安装时间种类
        unitTypeVal: '', // 设备型号
        // type
        sdkSelect: 0, // sdk选择方式
        equipSelect: 0, // 设备ID选择方式
        channelSelect: 0, // 渠道选择方式
        areaSelect: 0, // 国家选择方式
        chinaSelect: 0, // 中国地区选择方式
        dateSelect: 0, // 日期选择方式
        timeSelect: 0, // 时间选择方式
        brandSelect: 0, // 设备品牌选择方式
        operatorSelect: 0, // 运营商选择方式
        nettypeSelect: 0, // 网络类型选择方式
        sysVersionSelect: 0, // 系统版本选择方式
        appVersionSelect: 0, // app版本选择方式
        childchannelSelect: 0, // 子渠道选择方式
        custSelect: 0, // 自定义选择方式
        installTimeSelect: 2, // 应用安装时间选择方式
        unitTypeSelect: 0 // 设备型号选择方式
      }
      // 复制规则
      this.$set(this.formquery, 'name', item.name)
      this.$set(this.formquery, 'groupId', groupId)
      item.segmentRule.forEach((child) => {
        this.handlerEditInfo(child)
      })
    },
    // 添加日期子规则
    handleAddDateItem () {
      this.formquery.dateVal.push([])
    },
    // 删除日期子规则
    handleCloseDateItem (index) {
      this.formquery.dateVal.splice(index, 1)
    },
    // 添加时间子规则
    handleAddTimeItem () {
      this.formquery.timeVal.push([null, null])
    },
    // 删除时间子规则
    handleCloseTimeItem (index) {
      this.formquery.timeVal.splice(index, 1)
    },
    disabledEndHours (index, i) {},
    disabledEndMinutes (index, i) {},
    disabledEndSeconds (index, i) {},
    // 开启新规则
    handleClickRuleItemShow (showKey) {
      // 判断该规则是否已开启
      if (this.showLog[showKey]) return
      this.showLog[showKey] = true
      // 关闭下拉框
      this.$refs.popover.$children[0].sVisible = false
    },
    // 通过规则id关闭规则
    handleDeleteRuleItem (id) {
      const key = this.groupRules.filter((item) => item.id === id)[0].showKey
      this.showLog[key] = false
    },
    // 通过item数据回显form表单数据
    handlerEditInfo (item) {
      switch (item.ruleId) {
        case 2:
          this.showLog.sdkShow = true
          this.formquery.sdk = item.ruleContent.join(',')
          this.formquery.sdkSelect = item.ruleType
          break
        case 3:
          this.showLog.equipmentShow = true
          this.formquery.equipVal = item.ruleContent.join(',')
          this.formquery.equipSelect = item.ruleType
          break
        case 1:
          this.showLog.channelShow = true
          this.formquery.channelVal = item.ruleContent.join(',')
          this.formquery.channelSelect = item.ruleType
          break
        case 17:
          this.showLog.areaShow = true
          this.formquery.areaVal = item.ruleContent
          this.formquery.areaSelect = item.ruleType
          break
        case 4:
          const chinaArr = []
          item.ruleContent.forEach((ele) => {
            const temp = ele.split('-')
            chinaArr.push(temp)
          })
          this.formquery.chinaVal = chinaArr
          this.formquery.chinaSelect = item.ruleType
          break
        case 5:
          this.showLog.dateShow = true
          this.formquery.dateSelect = item.ruleType
          const time = item.ruleContent.map((item) => {
            const time1 = item.substr(0, 10)
            const time2 = item.substr(11, 10)
            return [moment(time1, 'YYYY/MM/DD'), moment(time2, 'YYYY/MM/DD')]
          })
          this.formquery.dateVal = time
          break
        case 6:
          this.formquery.timeVal = item.ruleContent.map((item) => {
            const timeStrArr = item.split('-')
            return [moment(timeStrArr[0], 'HH:mm'), moment(timeStrArr[1], 'HH:mm')]
          })
          this.formquery.timeSelect = item.ruleType
          this.showLog.timeShow = true
          break
        case 7:
          this.showLog.brandShow = true
          this.formquery.brandVal = item.ruleContent
          this.formquery.brandSelect = item.ruleType
          break
        case 8:
          this.showLog.nettypeShow = true
          this.formquery.nettypeVal = item.ruleContent
          this.formquery.nettypeSelect = item.ruleType
          break
        case 9:
          this.showLog.sysVersionShow = true
          this.formquery.sysVersion = item.ruleContent.join(',')
          this.formquery.sysVersionSelect = item.ruleType
          break
        case 10:
          this.showLog.appVersionShow = true
          this.formquery.appVersion = item.ruleContent.join(',')
          this.formquery.appVersionSelect = item.ruleType
          break
        case 11:
          this.showLog.childchannelShow = true
          this.formquery.childchannelVal = item.ruleContent.join(',')
          this.formquery.childchannelSelect = item.ruleType
          break
        case 12:
          this.showLog.custShow = true
          this.formquery.custVal = item.ruleContent.join(',')
          this.formquery.custSelect = item.ruleType
          break
        case 13:
          this.showLog.installTimeShow = true
          this.formquery.installTime = item.ruleContent[0] || ''
          this.formquery.installTimeType = item.ruleContent[1] || 'h'
          this.formquery.installTimeSelect = item.ruleType
          break
        case 14:
          this.showLog.unitTypeShow = true
          this.formquery.unitTypeVal = item.ruleContent.join(',')
          this.formquery.unitTypeSelect = item.ruleType
          break
        case 16:
          this.showLog.operatorShow = true
          this.formquery.operatorVal = item.ruleContent
          this.formquery.operatorSelect = item.ruleType
          break
        default:
      }
    },
    handleCancel () {
      this.addGroupVisible = false
    },
    // 确认按钮事件
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 通过校验，进行处理
          let segmentRule = []
          for (const key in this.showLog) {
            if (this.showLog[key]) {
              // 只处理开启的规则
              const ruleId = this.groupRules.filter((item) => item.showKey === key)[0].id
              if (!this.getRuleObj(ruleId)) {
                break
              }
              const { ruleType, ruleContent } = this.getRuleObj(ruleId)
              segmentRule.push({
                ruleId,
                ruleType,
                ruleContent
              })
            } else if (key === 'chinaShow' && this.showLog.areaShow && this.hasChina) {
              // 选了国家规则/选中中国
              const ruleId = 4
              if (!this.getRuleObj(ruleId)) {
                break
              }
              const { ruleType, ruleContent } = this.getRuleObj(ruleId)
              segmentRule.push({
                ruleId,
                ruleType,
                ruleContent
              })
            }
          }
          if (segmentRule.find((item) => +item.ruleId === 17) && !this.hasChina) {
            segmentRule = segmentRule.filter((item) => +item.ruleId !== 4)
          }
          const query = {
            segmentRule,
            placeId: this.placeId,
            name: this.formquery.name,
            groupId: this.formquery.groupId
          }

          if (this.isAdd) {
            const resp = await addGroup(query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeGroupInfo')
              this.addGroupVisible = false
            } else {
              this.$message.success(resp.msg)
            }
          } else {
            query.groupId = this.groupInfo.id
            query.impressFreqHour = +this.formquery.impressFreqHour || undefined
            query.impressFreqDay = +this.formquery.impressFreqDay || undefined
            query.impressInterval = +this.formquery.impressInterval || undefined
            let resp
            // 若是默认分组则不传name和规则列表，只调用修改展示接口
            if (this.isDefault) {
              delete query.name
              delete query.segmentRule
              resp = await updateImpress(query)
            } else {
              resp = await updateGroup(query)
            }
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeGroupInfo')
              this.addGroupVisible = false
            }
          }
        } else {
          return false
        }
      })
    },
    // 通过id处理规则形成提交传参的每一项规则数据
    getRuleObj (id) {
      switch (id) {
        case 2:
          return {
            ruleType: this.formquery.sdkSelect,
            ruleContent: this.formquery.sdk.split(/[,，]/g)
          }
        case 3:
          return {
            ruleType: this.formquery.equipSelect,
            ruleContent: this.formquery.equipVal.split(/[,，]/g)
          }
        case 1:
          return {
            ruleType: this.formquery.channelSelect,
            ruleContent: this.formquery.channelVal.split(/[,，]/g)
          }
        case 17:
          return {
            ruleType: this.formquery.areaSelect,
            ruleContent: this.formquery.areaVal
          }
        case 4:
          const chinaArr = []
          this.formquery.chinaVal.forEach((item) => {
            const a = item.join('-')
            chinaArr.push(a)
          })
          return {
            ruleType: this.formquery.chinaSelect,
            ruleContent: chinaArr
          }
        case 5:
          const dateRuleContent = this.formquery.dateVal
            .filter((item) => item.length > 0)
            .map((item) => {
              const date1 = item[0].format('YYYY-MM-DD')
              const date2 = item[1].format('YYYY-MM-DD')
              return date1 + '-' + date2
            })
          return {
            ruleType: this.formquery.dateSelect,
            ruleContent: dateRuleContent
          }
        case 6:
          const timeRuleContent = this.formquery.timeVal
            .filter((item) => item[0] && item[1])
            .map((item) => {
              const time1 = item[0].format('HH:mm')
              const time2 = item[1].format('HH:mm')
              return time1 + '-' + time2
            })
          if (timeRuleContent.length === 0) {
            return false
          }
          return {
            ruleType: this.formquery.timeSelect,
            ruleContent: timeRuleContent
          }
        case 7:
          if (this.formquery.brandVal.length === 0) {
            return false
          }
          return {
            ruleType: this.formquery.brandSelect,
            ruleContent: this.formquery.brandVal
          }
        case 8:
          if (this.formquery.nettypeVal.length === 0) {
            return false
          }
          return {
            ruleType: this.formquery.nettypeSelect,
            ruleContent: this.formquery.nettypeVal
          }
        case 9:
          return {
            ruleType: this.formquery.sysVersionSelect,
            ruleContent: this.formquery.sysVersion.split(/[,，]/g)
          }
        case 10:
          return {
            ruleType: this.formquery.appVersionSelect,
            ruleContent: this.formquery.appVersion.split(/[,，]/g)
          }
        case 11:
          return {
            ruleType: this.formquery.childchannelSelect,
            ruleContent: this.formquery.childchannelVal.split(/[,，]/g)
          }
        case 12:
          return {
            ruleType: this.formquery.custSelect,
            ruleContent: this.formquery.custVal.split(/[,，]/g)
          }
        case 13:
          return {
            ruleType: this.formquery.installTimeSelect,
            ruleContent: [this.formquery.installTime, this.formquery.installTimeType]
          }
        case 14:
          return {
            ruleType: this.formquery.unitTypeSelect,
            ruleContent: this.formquery.unitTypeVal.split(/[,，]/g)
          }
        case 16:
          if (this.formquery.operatorVal.length === 0) {
            return false
          }
          return {
            ruleType: this.formquery.operatorSelect,
            ruleContent: this.formquery.operatorVal
          }
        default:
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.form_wrapper .ant-select {
  padding-top: 2px;
}
.form_wrapper {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  border-left: 10px solid #fff;
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px fade(@primary-color, 3%);
    background: fade(@primary-color, 20%);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px fade(@primary-color, 3%);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.rules_wrapper {
  position: relative;
  min-height: 180px;
  display: flex;

  ul {
    flex: 1;
    padding: 0;
    border-left: 1px solid #eee;
    &:first-child {
      border: none;
    }
    li {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin-bottom: 5px;
      text-align: center;
      border-left: 4px solid #fff;
      cursor: pointer;
      color: #666;
      &.noChecked:hover {
        background: fade(@primary-color, 5%);
        border-left: 4px solid @primary-color;
      }
      &.hasChecked {
        background: #fff;
        cursor: inherit;
        color: #ccc;
      }
    }
  }
}
.form_item {
  margin-bottom: 0;
}
</style>
<style lang="less">
.group_modal .ant-modal-body {
  // background-color: #eee;
  .ant-row-flex {
    vertical-align: middle;
  }
}
</style>
