<template>
  <div
    v-if="
      isAdmin &&
        (+positionId === 1) &&
        (+platInfo.accessType === 1 || +platInfo.id === 1019 || (+platInfo.accessType === 0 && +formquery.isAutoDrawing))
    "
  >
    <!-- 响应区域比例 -->
    <a-form-model-item prop="respAreaRatio" label="响应区域比例">
      <a-input
        style="width: 250px"
        @input="changeRespAreaRatio"
        placeholder="请输入响应区域比例"
        v-model.number.trim="formquery.respAreaRatio"
      >
        <span slot="addonAfter">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 响应区域生效频次 -->
    <a-form-model-item prop="closeButtonFreq" label="响应区域生效频次">
      <a-input
        style="width: 250px"
        @input="changeCloseButtonFreq"
        placeholder="请输入响应区域生效频次"
        v-model.number.trim="formquery.closeButtonFreq"
      >
        <span slot="addonAfter">次/天</span>
      </a-input>
    </a-form-model-item>
    <!-- 响应区域生效间隔 -->
    <a-form-model-item prop="closeButtonInterval" label="响应区域生效间隔">
      <a-input
        style="width: 250px"
        @input="changeCloseButtonInterval"
        placeholder="请输入响应区域生效间隔"
        v-model.number.trim="formquery.closeButtonInterval"
      >
        <span slot="addonAfter">分</span>
      </a-input>
    </a-form-model-item>
    <!-- 响应区域第n次展示生效 -->
    <a-form-model-item prop="displayEffectiveCount" label="响应区域第n次展示生效">
      <a-input
        style="width: 250px"
        @input="changeDisplayEffectiveCount"
        placeholder="请使用英文逗号分隔"
        v-model.trim="formquery.displayEffectiveCount"
      >
      </a-input>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formqueryTemp: {
        respAreaRatio: undefined,
        closeButtonFreq: undefined,
        closeButtonInterval: undefined,
        displayEffectiveCount: undefined
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1 || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        respAreaRatio: undefined,
        closeButtonFreq: undefined,
        closeButtonInterval: undefined,
        displayEffectiveCount: undefined
      }),
      type: Object
    },
    positionId: {
      default: 0,
      type: Number
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    changeCloseButtonInterval (e) {
      this.formquery.closeButtonInterval = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },

    changeCloseButtonFreq (e) {
      this.formquery.closeButtonFreq = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changeRespAreaRatio (e) {
      this.formquery.respAreaRatio = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (this.formquery.respAreaRatio > 100) {
        this.formquery.respAreaRatio = 100
      } else if (this.formquery.respAreaRatio <= 0) {
        this.formquery.respAreaRatio = 1
      }
    },
    changeDisplayEffectiveCount (e) {
      this.formquery.displayEffectiveCount = (e.target.value || '').replace(/[^0-9,]/g, '') || undefined
    }
  }
}
</script>

<style lang="less" scoped>
</style>
