<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-09 17:07:13
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:37:28
 * @FilePath: /mediatom-web/src/components/sourceModal/IncentiveVideoSourceForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="+positionId === 5 && (+platInfo.accessType === 1 || +platInfo.id === 1019)">
    <!-- 激励视频显示 -->
    <a-form-model-item prop="drawType" label="激励视频显示">
      <a-radio-group name="radioGroup" v-model="formquery.drawType">
        <a-radio value="0"> 竖屏 </a-radio>
        <a-radio value="1"> 横屏 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <template v-if="isAdmin">
      <!-- 倒计时时长 -->
      <a-form-model-item prop="rdCountdown">
        <m-tips
          slot="label"
          :content="`控制图片渲染成激励视频类型的广告，总倒计时时长。`"
          :title="'倒计时时长'"
          :width="200"
        ></m-tips>
        <a-input
          style="width: 250px"
          @input="changeRdCountdown"
          placeholder="请输入倒计时时长"
          v-model.trim="formquery.rdCountdown"
        >
          <span slot="addonAfter">s</span>
        </a-input>
      </a-form-model-item>
      <!-- n秒显示跳过 -->
      <a-form-model-item prop="rdSkipTime">
        <m-tips
          slot="label"
          :content="`显示跳过按钮的时间，如不设置，不显示跳过按钮。`"
          :title="'n秒显示跳过'"
          :width="200"
        ></m-tips>
        <a-input
          style="width: 250px"
          @input="changeRdSkipTime"
          placeholder="请输入n秒显示跳过"
          v-model.trim="formquery.rdSkipTime"
        >
          <span slot="addonAfter">s</span>
        </a-input>
      </a-form-model-item>
      <!-- 发放方式 -->
      <a-form-model-item v-if="isAdmin" prop="rdType">
        <m-tips
          slot="label"
          :content="`控制奖励发放的方式<br/>
点击后发放：<br/>
设置后会在指定时间出现“点击后立刻获取奖励”提示。出现比例用于控制该功能生效的比例。<br/>
自动发放：<br/>
视频播放完成或播放到指定时间发放奖励。如未设置，按自动发放处理。`"
          :title="'发放方式'"
          :width="200"
        ></m-tips>
        <a-radio-group name="radioGroup" v-model="formquery.rdType">
          <a-radio :value="0"> 点击后发放 </a-radio>
          <a-radio :value="1"> 自动发放 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- 出现时间 -->
      <a-form-model-item v-if="formquery.rdType === 0 && isAdmin" prop="rdClickTime" label="出现时间">
        <a-input
          style="width: 250px"
          @input="changeRdClickTime"
          placeholder="请输入出现时间"
          v-model.trim="formquery.rdClickTime"
        >
          <span slot="addonAfter">s</span>
        </a-input>
      </a-form-model-item>
      <!-- 出现比例 -->
      <a-form-model-item v-if="formquery.rdType === 0 && isAdmin" prop="rdClickRatio" label="出现比例">
        <a-input
          style="width: 250px"
          @input="changeRdClickRatio"
          placeholder="请输入出现比例"
          v-model.trim="formquery.rdClickRatio"
        >
          <span slot="addonAfter">%</span>
        </a-input>
      </a-form-model-item>
      <!-- 自动发放奖励类型 -->
      <a-form-model-item v-if="isAdmin" prop="rdAutoType">
        <m-tips
          slot="label"
          :content="`控制奖励发放的时间节点<br/>
定时发放：<br/>
播放到设置的时间发放奖励。<br/>
播放完发放：<br/>
视频播放完成发放奖励。`"
          :title="'自动发放奖励类型'"
          :width="200"
        ></m-tips>
        <a-radio-group name="radioGroup" v-model="formquery.rdAutoType">
          <a-radio :value="1"> 定时发放 </a-radio>
          <a-radio :value="2"> 播放完发放 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- 定时发放时间 -->
      <a-form-model-item v-if="formquery.rdAutoType === 1 && isAdmin" prop="rdAutoTime" label="定时发放时间">
        <a-input
          style="width: 250px"
          @input="changeRdAutoTime"
          placeholder="请输入定时发放时间"
          v-model.trim="formquery.rdAutoTime"
        >
          <span slot="addonAfter">s</span>
        </a-input>
      </a-form-model-item>
      <!-- 自动跳转 -->
      <a-form-model-item prop="rdAutoskipSts" v-if="isAdmin">
        <m-tips
          slot="label"
          :content="`发放奖励前5秒，倒计时提示用户自动打开落地页，发放奖励同时自动点击广告。用户可以手动取消此功能。`"
          :title="'自动跳转'"
          :width="200"
        ></m-tips>
        <a-radio-group name="radioGroup" v-model="formquery.rdAutoskipSts">
          <a-radio value="A"> 开启 </a-radio>
          <a-radio value="S"> 关闭 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'IncentiveVideoSourceForm',
  data () {
    return {
      formqueryTemp: {
        rdCountdown: undefined,
        rdSkipTime: undefined,
        rdType: 0,
        rdClickTime: undefined,
        rdClickRatio: undefined,
        rdAutoType: 1,
        rdAutoTime: undefined,
        rdAutoskipSts: 'S',
        drawType: '0'
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1 || +this.adminUserId === 25) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    positionId: {
      default: 0,
      type: Number
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
    changeRdCountdown (e) {
      this.formquery.rdCountdown = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changeRdSkipTime (e) {
      this.formquery.rdSkipTime = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changeRdClickTime (e) {
      this.formquery.rdClickTime = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    },
    changeRdClickRatio (e) {
      this.formquery.rdClickRatio = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (this.formquery.rdClickRatio > 100) {
        this.formquery.rdClickRatio = 100
      } else if (this.formquery.rdClickRatio < 0) {
        this.formquery.rdClickRatio = 0
      }
    },
    changeRdAutoTime (e) {
      this.formquery.rdAutoTime = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
    }
  }
}
</script>

<style lang="less" scoped></style>
