<template>
  <a-modal
    v-model="addSourceVisible"
    :title="(isAdd ? '添加' : '修改') + '广告源'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="isAdd ? 940 : 900"
    dialogClass="source_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button
          v-if="origin === 'https://app.mediatom.cn'"
          style="margin-right: 500px"
          type="link"
          target="_blank"
          href="https://app.mediatom.cn/docs/#/?blogid=125"
        >如何配置广告源?</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <div class="source_wrapper_form" :style="{ 'margin-left': '0' }">
          <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
            <div class="APIItem">
              <!-- 广告平台名称 -->
              <div class="item1">
                <div class="itemPlat">广告平台:</div>
                <div class="itemPlatName">{{ platInfo.name }}</div>
              </div>

              <!-- 账户 -->
              <div class="item2" v-if="platInfo.id !== 1019">
                <div class="itemPlat">账户:</div>
                <div class="itemPlatName">{{ formquery?.accountName }}</div>
              </div>
            </div>
            <!-- 广告源名称 -->
            <a-form-model-item
              :rules="[{ required: true, message: '请输入广告源名称', trigger: 'blur' }]"
              label="广告源名称"
              prop="name"
            >
              <a-input placeholder="请输入广告源名称" v-model.trim="formquery.name" />
            </a-form-model-item>
            <!-- 应用ID -->
            <a-form-model-item
              :rules="[{ required: true, message: '请输入应用ID', trigger: 'blur' }]"
              v-if="
                platInfo.accessType !== '2' &&
                  platInfo.id !== 5 &&
                  platInfo.id !== 1001 &&
                  platInfo.id !== 1027 &&
                  platInfo.id !== 1024 &&
                  platInfo.id !== 1031 &&
                  platInfo.id !== 1047
              "
              prop="platAppId"
            >
              <m-tips
                slot="label"
                :content="`对应广告平台创建应用后，平台生成的应用id。`"
                :title="'应用ID'"
                :width="200"
              ></m-tips>
              <a-input v-if="allowEdit" placeholder="请输入应用ID" v-model.trim="formquery.platAppId" />
              <div v-else>
                <span>{{ formquery.platAppId }}</span>
                <template v-if="!sdkDisabled">
                  <a-button type="link" size="small" @click="changeAllowEdit">编辑</a-button>
                </template>
              </div>
              <a-alert
                v-if="isAdd === 2 && allowEdit"
                message="修改应用ID参数后，所选账号下的所有广告位配置都会同步生效！"
                banner
              />
            </a-form-model-item>
            <!-- 自动化创建广告源 -->
            <!--
                1.账号信息存在
                2.账号信息中的 自动创建广告源 未开启
                3.优量汇----- 开屏 激励视频  原生自渲染4 原生模板6 (原生混合 && adStyle有值)
                4.快手---- 插屏 激励视频 开屏  原生自渲染4 原生模板6 (原生混合 && adStyle有值)
                5.穿山甲---- 插屏3 激励视频5 开屏1 原生自渲染4 原生模板6 (原生混合 && adStyle有值)
                5.百度---- 插屏3 激励视频5 开屏1 原生自渲染4 原生模板6 (原生混合 && adStyle有值)
              -->
            <a-form-model-item
              v-if="
                accountInfo &&
                  !+accountInfo.isAutoCreate &&
                  ((+platInfo.id === 2 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 3 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 1 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 6 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))))
              "
            >
              <m-tips
                slot="label"
                :content="`开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在【${
                  +platInfo.id === 1
                    ? '穿山甲'
                    : +platInfo.id === 2
                      ? '优量汇'
                      : +platInfo.id === 3
                        ? '快手'
                        : +platInfo.id === 6
                          ? '百度联盟'
                          : '-'
                }】后台创建【${
                  +platInfo.id === 1
                    ? '代码位'
                    : +platInfo.id === 2
                      ? '广告位'
                      : +platInfo.id === 3
                        ? '广告位'
                        : +platInfo.id === 6
                          ? '代码位'
                          : '-'
                }】`"
                title="自动创建广告源"
                :width="200"
              ></m-tips>
              <div>
                去<span
                  style="color: #338aff; cursor: pointer"
                  class="text-a"
                  @click="handleClickEditAcount"
                >授权开启</span
                >
              </div>
            </a-form-model-item>
            <!-- 广告位类型 -->
            <!-- 穿山甲、优量汇、快手、百度展示广告类型 -->
            <a-form-model-item
              label="广告位类型"
              v-if="
                positionId === 10 &&
                  (formquery.platId === 1 || formquery.platId === 2 || formquery.platId === 3 || formquery.platId === 6)
              "
              :rules="[{ required: true, message: '请选择广告位类型', trigger: 'change' }]"
              prop="adType"
            >
              <a-select
                placeholder="请选择广告位类型"
                v-model="formquery.adType"
                @change="changeAdType"
                showSearch
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in adTypeList"
                  :key="item.id"
                  :value="item.id"
                  showSearch
                  :filter-option="filterOption"
                >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <!-- 渲染方式 -->
            <!-- 不展示条件 选择快手、广告位类型是draw视频 或 选择百度、广告位类型是频贴片、竖板视频-->
            <a-form-model-item
              label="渲染方式"
              v-if="showAdType"
              :rules="[{ required: true, message: '请选择渲染方式', trigger: 'change' }]"
              prop="adStyle"
            >
              <a-select
                placeholder="请选择渲染方式"
                v-model="formquery.adStyle"
                showSearch
                :filter-option="filterOption"
                @change="
                  () => {
                    $refs.ruleForm.clearValidate()
                    formquery.isAutoCreate = 0
                  }
                "
              >
                <a-select-option
                  v-for="item in adStyleList"
                  :key="item.id"
                  :value="item.id"
                  showSearch
                  :filter-option="filterOption"
                >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <!-- 自动化创建广告源 -->
            <!--
                1.账号信息存在
                2.账号信息中的 自动创建广告源 已开启
                3.优量汇----- 插屏 开屏 激励视频 原生自渲染 原生模板  (原生混合 && adStyle有指)
                4.快手---- 插屏 激励视频 开屏 原生自渲染 原生模板  (原生混合 && adStyle有指)
                5.穿山甲---- 插屏 激励视频 开屏 原生自渲染 原生模板  (原生混合 && adStyle有指 && 广告位类型不为视频贴片2)
              -->
            <a-form-model-item
              prop="isAutoCreate"
              v-if="
                accountInfo &&
                  +accountInfo.isAutoCreate &&
                  isAdd &&
                  ((+platInfo.id === 2 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 3 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 1 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle))) ||
                  (+platInfo.id === 6 &&
                  (positionId === 1 ||
                  positionId === 5 ||
                  positionId === 3 ||
                  positionId === 4 ||
                  positionId === 6 ||
                  (+positionId === 10 && formquery.adStyle && +formquery.adType !== 2))))
              "
            >
              <m-tips
                slot="label"
                :content="`开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在【${
                  +platInfo.id === 1
                    ? '穿山甲'
                    : +platInfo.id === 2
                      ? '优量汇'
                      : +platInfo.id === 3
                        ? '快手'
                        : +platInfo.id === 6
                          ? '百度联盟'
                          : ''
                }】后台创建【${
                  +platInfo.id === 1
                    ? '代码位'
                    : +platInfo.id === 2
                      ? '广告位'
                      : +platInfo.id === 3
                        ? '广告位'
                        : +platInfo.id === 6
                          ? '代码位'
                          : ''
                }】`"
                title="自动创建广告源"
                :width="200"
              ></m-tips>
              <a-radio-group name="radioGroup" v-model="formquery.isAutoCreate" @change="initAutoCreateForm">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!--
                自动创建广告源传参
                1.穿山甲 or 优量汇 or 快手
                2.账户信息存在
                3.账户信息中的 自动创建广告源  已开启
                4.添加状态
                5.表单中的 自动创建广告源  已开启
              -->
            <template
              v-if="
                (+platInfo.id === 2 || +platInfo.id === 3 || +platInfo.id === 1 || +platInfo.id === 6) &&
                  accountInfo &&
                  +accountInfo.isAutoCreate &&
                  isAdd &&
                  formquery.isAutoCreate
              "
            >
              <!-- 广告场景 -->
              <!--
                  优量汇 and （开屏 or 激励视频）
                -->
              <a-form-model-item
                label="广告场景"
                prop="scene"
                :rules="[{ required: true, message: '请选择广告场景', trigger: 'change' }]"
                v-if="+platInfo.id === 2 && (+positionId === 1 || +positionId === 5)"
              >
                <a-select placeholder="请选择广告场景" v-model="formquery.scene">
                  <a-select-option v-for="item in sceneList" :key="item.value" :value="item.value">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 渲染类型 -->
              <!--
                  优量汇 and (开屏 or  激励视频)
                -->
              <a-form-model-item
                label="渲染类型"
                prop="crtType"
                v-if="+platInfo.id === 2 && (+positionId === 1 || +positionId === 5)"
                :rules="[{ required: true, message: '请选择渲染类型', trigger: 'change' }]"
              >
                <a-radio-group name="radioGroup" v-model="formquery.crtType" @change="changeCrtType">
                  <a-radio v-for="item in crtTypeList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 素材类型 -->
              <!--
                  优量汇 and 开屏  and  渲染类型有值
                -->
              <a-form-model-item
                label="素材类型"
                prop="adCrtTypeList"
                v-if="formquery.crtType && +positionId === 1 && +platInfo.id === 2"
                :rules="[{ required: true, message: '请选择素材类型', trigger: 'change' }]"
              >
                <a-checkbox-group v-model="formquery.adCrtTypeList" name="checkboxgroup">
                  <a-checkbox
                    v-if="formquery.crtType === 'FLASH_V_PLUS'"
                    :disabled="true"
                    value="FLASH_VIDEO_LONG"
                  >开屏:5-30秒长视频+首屏续播</a-checkbox
                  >
                  <a-checkbox :disabled="true" value="FLASH_IMAGE_V">开屏:图片</a-checkbox>
                  <a-checkbox
                    :disabled="formquery.crtType === 'FLASH_V_PLUS'"
                    value="FLASH_VIDEO_V"
                  >开屏:5s视频</a-checkbox
                  >
                </a-checkbox-group>
              </a-form-model-item>
              <!-- 素材类型 -->
              <!--
                  插屏 and  快手
                -->
              <a-form-model-item
                label="素材类型"
                prop="adCrtTypeList"
                v-if="+positionId === 3 && +platInfo.id === 3"
                :rules="[{ required: true, message: '请选择素材类型', trigger: 'change' }]"
              >
                <a-checkbox-group v-model="formquery.adCrtTypeList" name="checkboxgroup">
                  <a-checkbox :value="1">竖版视频</a-checkbox>
                  <a-checkbox :value="2">横版视频</a-checkbox>
                  <a-checkbox :value="5">竖版图片</a-checkbox>
                  <a-checkbox :value="6">横版图片</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
              <!-- 跳过按钮是否显示倒计时 -->
              <!--
                  快手 and 开屏
                -->
              <a-form-model-item
                label="跳过按钮是否显示倒计时"
                prop="countdownShow"
                v-if="+platInfo.id === 3 && +positionId === 1"
                :rules="[{ required: true, message: '请选择跳过按钮是否显示倒计时', trigger: 'change' }]"
              >
                <a-radio-group name="radioGroup" v-model="formquery.countdownShow">
                  <a-radio :value="1">显示</a-radio>
                  <a-radio :value="0">不显示</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 视频播放方向 -->
              <!--
                  快手 and 激励视频
                -->
              <a-form-model-item
                label="视频播放方向"
                prop="adCrtTypeList"
                v-if="+platInfo.id === 3 && +positionId === 5"
                :rules="[{ required: true, message: '请选择视频播放方向', trigger: 'change' }]"
              >
                <a-select placeholder="请选择视频播放方向" v-model="formquery.adCrtTypeList[0]">
                  <a-select-option :value="1">竖屏</a-select-option>
                  <a-select-option :value="2">插屏</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 奖励名称 -->
              <!--
                  快手 and 激励视频
                -->
              <a-form-model-item
                label="奖励名称"
                prop="rewardedType"
                v-if="+platInfo.id === 3 && +positionId === 5"
                :rules="[{ required: true, message: '请选择奖励名称', trigger: 'change' }]"
              >
                <a-select placeholder="请选择奖励名称" v-model="formquery.rewardedType">
                  <a-select-option v-for="item in rewardedTypeList" :key="item.value" :value="item.value">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 奖励名称 -->
              <!--
                  快手 and 激励视频
                -->
              <a-form-model-item
                label="奖励数量；"
                prop="rewardedNum"
                v-if="+platInfo.id === 3 && positionId === 5"
                :rules="[{ required: true, message: '请输入奖励数量', trigger: 'blur' }]"
              >
                <a-input-number :min="0" placeholder="请输入奖励数量" v-model.trim="formquery.rewardedNum" />
              </a-form-model-item>
              <!-- 屏幕方向 -->
              <!--
                  穿山甲 and ( 开屏 or 激励视频 or 插屏 )
                -->
              <a-form-model-item
                label="屏幕方向"
                prop="orientation"
                v-if="+platInfo.id === 1 && (+positionId === 1 || +positionId === 5 || +positionId === 3)"
              >
                <a-radio-group name="radioGroup" v-model="formquery.orientation">
                  <a-radio :value="1">竖屏</a-radio>
                  <a-radio :value="2">横屏</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 屏幕方向 -->
              <!--
                  穿山甲 and 插屏
                -->
              <a-form-model-item
                label="⼴告铺开⼤⼩"
                prop="adRolloutSize"
                v-if="+platInfo.id === 1 && +positionId === 3"
              >
                <a-radio-group name="radioGroup" v-model="formquery.adRolloutSize">
                  <a-radio
                    :value="1"
                  ><m-tips
                    content="n秒后显示跳过按钮：配置范围为5～15的整数，用以控制广告跳过按钮展示时机"
                    :title="'全屏'"
                    :width="200"
                  ></m-tips
                  ></a-radio>
                  <a-radio :value="2">
                    <m-tips
                      content="n秒后显示跳过按钮：配置范围为0～15的整数，用以控制广告跳过按钮展示时机"
                      :title="'半屏'"
                      :width="200"
                    ></m-tips
                    ></a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- n秒后显⽰跳过按钮 -->
              <!--
                  穿山甲 and 插屏
                -->
              <a-form-model-item
                label="n秒后显⽰跳过按钮"
                prop="skipDuration"
                v-if="+platInfo.id === 1 && +positionId === 3"
                :rules="[{ required: true, message: '请输入时间', trigger: 'blur' }]"
              >
                <a-input-number
                  :min="formquery.adRolloutSize === 1 ? 5 : 0"
                  :max="15"
                  placeholder="请输入时间"
                  v-model.trim="formquery.skipDuration"
                />
              </a-form-model-item>
              <!-- 视频声⾳ -->
              <!--
                  穿山甲 and 插屏
                -->
              <a-form-model-item
                label="视频声⾳"
                prop="videoVoiceControl"
                v-if="+platInfo.id === 1 && +positionId === 3"
              >
                <a-radio-group name="radioGroup" v-model="formquery.videoVoiceControl">
                  <a-radio :value="1">静音</a-radio>
                  <a-radio :value="2">有声音</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 素材类型 -->
              <!--
                  穿山甲 and 插屏
                -->
              <a-form-model-item
                label="素材类型"
                prop="adCrtTypeList"
                v-if="+platInfo.id === 1 && +positionId === 3"
                :rules="[{ required: true, message: '请选择素材类型', trigger: 'change' }]"
              >
                <a-select placeholder="请选择素材类型" v-model="formquery.adCrtTypeList[0]">
                  <a-select-option :value="1">仅图⽚</a-select-option>
                  <a-select-option :value="2">仅视频</a-select-option>
                  <a-select-option :value="3">图⽚+视频</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 素材类型 -->
              <!--
                  穿山甲 and (原生模板6 or (原生混合10  && adStyle===1有值 && adstyle !== 2))
                -->
              <a-form-model-item
                label="素材类型"
                prop="adCrtTypeList"
                v-if="
                  +platInfo.id === 1 &&
                    (+positionId === 6 || (+positionId === 10 && +formquery.adStyle === 1 && +formquery.adType !== 2))
                "
                :rules="[{ required: true, message: '请选择视素材类型', trigger: 'change' }]"
              >
                <a-radio-group
                  name="radioGroup"
                  v-model="formquery.adCrtTypeList[0]"
                  @change="formquery.templateIdList = []"
                >
                  <a-radio :value="1">仅图⽚</a-radio>
                  <a-radio :value="2">仅视频</a-radio>
                  <a-radio :value="3">图⽚+视频</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 素材类型 -->
              <!--
                  快手 and （原生模板6 or 原生自渲染4 or (原生混合 && adSytle有值 && adtype !== 2)）
                -->
              <a-form-model-item
                label="素材类型"
                prop="adCrtTypeList"
                v-if="
                  +platInfo.id === 3 &&
                    (+positionId === 6 ||
                    +positionId === 4 ||
                    (+positionId === 10 && +formquery.adStyle && +formquery.adType !== 2))
                "
                :rules="[{ required: true, message: '请选择素材类型', trigger: 'change' }]"
              >
                <a-checkbox-group
                  v-model="formquery.adCrtTypeList"
                  name="checkboxgroup"
                  @change="ChangeKSPositionId6AdCrtTypeList"
                >
                  <a-checkbox :value="1">竖版视频</a-checkbox>
                  <a-checkbox :value="2">横版视频</a-checkbox>
                  <a-checkbox :value="5">竖版图片</a-checkbox>
                  <a-checkbox :value="6">横版图片</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
              <!-- 广告样式 -->
              <!--
                  穿山甲 and (原生自渲染4 || (原生混合10 && adStyle===2 && adtype!==2 && adtype!==3))
                -->
              <a-form-model-item
                label="广告样式"
                prop="templateIdList"
                v-if="
                  +platInfo.id === 1 &&
                    (+positionId === 4 ||
                    (+positionId === 10 &&
                    +formquery.adStyle === 2 &&
                    +formquery.adType !== 2 &&
                    +formquery.adType !== 3))
                "
                :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 400px"
                  v-model="formquery.templateIdList"
                  :allData="[
                    { id: '1', name: '⼤图' },
                    { id: '2', name: '组图' },
                    { id: '3', name: '单图' },
                    { id: '4', name: '视频' },
                    { id: '5', name: '竖版视频' },
                    { id: '6', name: '竖图' }
                  ]"
                  :searchById="false"
                  :showLabel="false"
                  :hasIcon="false"
                  :showId="false"
                  width="450px"
                  label="广告样式"
                />
              </a-form-model-item>
              <!-- 模版样式 -->
              <!--
                  穿山甲 and (原生模板6 or (原生混合10 and adStyle === 1 && adtype !==2))
                -->
              <a-form-model-item
                label="模版样式"
                prop="templateIdList"
                v-if="
                  +platInfo.id === 1 &&
                    (+positionId === 6 || (+positionId === 10 && +formquery.adStyle === 1 && +formquery.adType !== 2))
                "
                :rules="[{ required: true, message: '请选择模版样式', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 400px"
                  v-model="formquery.templateIdList"
                  :allData="templateIdListForCSJ6"
                  :searchById="false"
                  :showLabel="false"
                  :hasIcon="false"
                  :showId="false"
                  width="450px"
                  label="模版样式"
                />
              </a-form-model-item>
              <!-- 视频声⾳ -->
              <!--
                  穿山甲 and (源生自渲染4 or (原生混合10 && adStyle === 2 && adtype !== 2)) and (formquery.templateIdList中选择4或5)
                  or
                  穿山甲 and (原生模板6 or (原生混合10 && adStyle === 1 && adtype !== 2)) and (formquery.adCrtTypeList[0] === 2 or 3)
                -->
              <a-form-model-item
                label="视频声⾳"
                prop="videoVoiceControl"
                v-if="
                  +platInfo.id === 1 &&
                    (((+positionId === 4 ||
                    (+positionId === 10 && +formquery.adStyle === 2 && +formquery.adType !== 2)) &&
                    (formquery.templateIdList.includes(4) || formquery.templateIdList.includes(5))) ||
                    ((+positionId === 6 ||
                    (+positionId === 10 && +formquery.adStyle === 1 && +formquery.adType !== 2)) &&
                    (formquery.adCrtTypeList[0] === 2 || formquery.adCrtTypeList[0] === 3)))
                "
              >
                <a-radio-group name="radioGroup" v-model="formquery.videoVoiceControl">
                  <a-radio :value="1">静音</a-radio>
                  <a-radio :value="2">有声音</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 视频声⾳ -->
              <!--
                  快手 and (原生模板6 or (原生混合10 && adStyle === 1 && adtype!==2))
                  or
                  快手 and (源生自渲染4 or (原生混合10 && adStyle === 2 && adtype!==2))
                  and
                  视频素材
                -->
              <a-form-model-item
                label="视频声⾳"
                prop="videoVoiceControl"
                v-if="
                  +platInfo.id === 3 &&
                    (+positionId === 4 ||
                    +positionId === 6 ||
                    (+positionId === 10 && +formquery.adStyle && +formquery.adType !== 2)) &&
                    isVideoTypeKS
                "
              >
                <a-radio-group name="radioGroup" v-model="formquery.videoVoiceControl">
                  <a-radio :value="1">静音</a-radio>
                  <a-radio :value="2">有声音</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 视频自动播放 -->
              <!--
                  快手 and (原生模板6 or (原生混合10 && adStyle === 1 && adtype!==2))
                  or
                  快手 and (源生自渲染4 or (原生混合10 && adStyle === 2 && adtype!==2))
                  and
                  视频素材
                -->
              <a-form-model-item
                label="视频自动播放"
                prop="videoAutoPlayType"
                v-if="
                  +platInfo.id === 3 &&
                    (+positionId === 4 ||
                    +positionId === 6 ||
                    (+positionId === 10 && +formquery.adStyle && +formquery.adType !== 2)) &&
                    isVideoTypeKS
                "
                :rules="[{ required: true, message: '请选择自动播放类型', trigger: 'change' }]"
              >
                <a-radio-group name="radioGroup" v-model="formquery.videoAutoPlayType">
                  <a-radio :value="1">有网络下自动播放</a-radio>
                  <a-radio :value="2">仅wifi下自动播放</a-radio>
                  <a-radio :value="3">不自动播放</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 模版样式 -->
              <!--
                  快手 and (原生模板6 or (原生混合10 && adStyle === 1 && adtype!==2))
                -->
              <a-form-model-item
                label="模版样式"
                prop="templateIdList"
                v-if="
                  +platInfo.id === 3 &&
                    (+positionId === 6 || (+positionId === 10 && formquery.adStyle === 1 && +formquery.adType !== 2))
                "
                :rules="[{ required: true, message: '请选择模版样式', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 400px"
                  v-model="formquery.templateIdList"
                  :allData="templateIdListForKS6"
                  :searchById="false"
                  :showLabel="false"
                  :hasIcon="false"
                  :showId="false"
                  width="450px"
                  label="模版样式"
                />
              </a-form-model-item>
              <!-- 广告场景 -->
              <!--
                  优量汇 and (原生模板 or 原生自渲染)
                -->
              <a-form-model-item label="广告场景" v-if="+platInfo.id === 2 && (+positionId === 4 || +positionId === 6)">
                <a-radio-group
                  v-model="formquery.scene"
                  @change="
                    () => {
                      formquery.templateIdList = ['1', '2', '3', '4', '5', '6']
                    }
                  "
                >
                  <a-radio :value="1">信息流广告</a-radio>
                  <a-radio :value="2">详情页插入广告</a-radio>
                  <a-radio :value="3">视频贴片广告</a-radio>
                  <a-radio v-if="positionId === 4" :value="4">沉浸式视频流</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 模版样式 -->
              <!--
                  优量汇 and (原生自渲染4 or (原生混合10 && adStyle === 2))
                -->
              <a-form-model-item
                label="模版样式"
                prop="templateIdList"
                v-if="+platInfo.id === 2 && (+positionId === 4 || (+positionId === 10 && +formquery.adStyle === 2))"
                :rules="[{ required: true, message: '请选择模版样式', trigger: 'change' }]"
              >
                <!-- [
                      { id: '1', name: '16:9 图片' },
                      { id: '2', name: '16:9 视频' },
                      { id: '3', name: '3:2 三小图' },
                      { id: '4', name: '9:16 图片' },
                      { id: '5', name: '9:16 视频' },
                      { id: '6', name: '20:3 图片' }
                    ] -->
                <m-select-more
                  style="width: 400px"
                  v-model="formquery.templateIdList"
                  :allData="plat2TemplateList"
                  :searchById="false"
                  :showLabel="false"
                  :hasIcon="false"
                  :showId="false"
                  width="450px"
                  label="模版样式"
                />
              </a-form-model-item>
              <!-- 模版样式 -->
              <!--
                  优量汇 and (原生模板6 or (原生混合10 && adstyle === 1))
                -->
              <a-form-model-item
                label="模版样式"
                prop="templateIdList"
                v-if="+platInfo.id === 2 && (+positionId === 6 || (+positionId === 10 && +formquery.adStyle === 1))"
                :rules="[{ required: true, message: '请选择模版样式', trigger: 'change' }]"
              >
                <m-select-more
                  style="width: 400px"
                  v-model="formquery.templateIdList"
                  :allData="[
                    { id: '1', name: '上图下文（支持视频）' },
                    { id: '2', name: '上文下图（支持视频）' },
                    { id: '3', name: '左图右文' },
                    { id: '4', name: '左文右图' },
                    { id: '5', name: '双图双文（支持视频）' },
                    { id: '6', name: '纯图竖版' },
                    { id: '7', name: '纯图横版（支持视频）' },
                    { id: '8', name: '三小图双文' },
                    { id: '9', name: '上文下图-文字浮层' },
                    { id: '10', name: '上图下文-文字浮层' },
                    { id: '11', name: '单图单文-文字浮层' }
                  ]"
                  :searchById="false"
                  :showLabel="false"
                  :hasIcon="false"
                  :showId="false"
                  width="450px"
                  label="模版样式"
                  @change="changeYLHTemplateIdList"
                />
              </a-form-model-item>
              <BaiduAutoCreateForm
                @changeFormQuery="changeFormQuery"
                v-if="+platInfo.id === 6"
                :formRef="$refs.ruleForm"
                :formquery="formquery"
                :positionId="positionId"
              />
            </template>
            <!-- 广告源ID -->
            <!--
                !(穿山甲 且 表单开启自动创建广告源) and
                !(优量汇 且 表单开启自动创建广告源) and
                !(快手 且 表单开启自动创建广告源) and
                非自定义广告源
              -->
            <a-form-model-item
              :rules="[{ required: true, message: `请输入${platPlaceLabel(platInfo.id)}`, trigger: 'blur' }]"
              prop="platPlaceId"
              v-if="
                !(platInfo.id === 2 && formquery.isAutoCreate) &&
                  !(platInfo.id === 3 && formquery.isAutoCreate) &&
                  !(platInfo.id === 1 && formquery.isAutoCreate) &&
                  !(platInfo.id === 6 && formquery.isAutoCreate) &&
                  platInfo.accessType !== '2'
              "
            >
              <!-- mtg -->
              <m-tips
                v-if="platInfo.id === 11"
                slot="label"
                content="Mintegral的广告单元ID为必填项。Mintegral SDK在请求广告时必须使用广告单元ID"
                :title="platPlaceLabel(platInfo.id)"
                :width="200"
              ></m-tips>
              <!-- 其他广告平台label -->
              <m-tips
                v-else
                slot="label"
                :content="`对应广告平台创建应用中创建广告位后，生成的广告位id。`"
                :width="200"
                :title="platPlaceLabel(platInfo.id)"
              ></m-tips>
              <a-input
                :placeholder="`请输入${platPlaceLabel(platInfo.id)}`"
                v-model.trim="formquery.platPlaceId"
                :disabled="platInfo.id === 5 || sdkDisabled"
              />
            </a-form-model-item>
            <!-- 广告场景 -->
            <a-form-model-item
              v-if="platInfo.id === 1027"
              label="广告场景"
              prop="adScene"
              :rules="[{ required: true, message: '请选择广告场景', trigger: 'change' }]"
            >
              <a-select placeholder="请选择广告场景" v-model="formquery.adScene">
                <a-select-option value="0">贴片</a-select-option>
                <a-select-option value="1">信息流</a-select-option>
                <a-select-option value="2">开机屏</a-select-option>
                <a-select-option value="3">暂停</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 播放形式ID -->
            <!--
                拼多多
              -->
            <a-form-model-item
              v-if="+platInfo.id === 1001"
              label="播放形式ID"
              prop="slotId"
              :rules="[{ required: true, message: '请输入播放形式ID', trigger: 'blur' }]"
            >
              <a-input placeholder="请输入播放形式ID" v-model.trim="formquery.slotId" />
            </a-form-model-item>
            <!-- appkey -->
            <!--
                sigmob or  mtg
              -->
            <a-form-model-item v-if="showAppKey(platInfo.id)" label="AppKey" prop="appKey">
              <a-input placeholder="请输入AppKey" v-model.trim="formquery.appKey" />
            </a-form-model-item>
            <!-- 广告版位ID -->
            <!-- mtg -->
            <a-form-model-item v-if="formquery.platId === 11" prop="slotId">
              <m-tips
                slot="label"
                content="Mintegral的广告版位ID为可选项。Mintegral的广告版位ID可以在Mintegral的开发者平台获取"
                :title="'广告版位ID'"
                :width="200"
              ></m-tips>
              <a-input placeholder="请输入广告版位ID" v-model.trim="formquery.slotId" />
            </a-form-model-item>
            <!-- 参数 -->
            <!--
                自定义广告源 and id不为1014
              -->
            <a-form-model-item
              v-if="platInfo.accessType === '2' && +platInfo.id !== 1014"
              label="参数"
              prop="ext"
              :rules="[{ required: true, validator: extValidator, trigger: 'blur' }]"
            >
              <a-input type="textarea" placeholder="参数必须符合JSON格式" v-model.trim="formquery.ext" />
            </a-form-model-item>
            <template v-if="+platInfo.accessType !== 1">
              <!-- 素材模板 -->
              <!-- [995, 989, 1020, 987, 988, 997, 998, 1011, 1014, 1025, 1026, 1028， 1031] -->
              <a-form-model-item v-if="showTemplateIds" label="素材模板" prop="templateIds">
                <m-select-one
                  :popoverWidth="'400px'"
                  v-if="hasGetTemplateList"
                  label="素材模板"
                  :showId="false"
                  :hasOs="false"
                  :hasPosition="false"
                  :allData="templeteList"
                  v-model="formquery.templateId"
                  :allowClear="true"
                />
              </a-form-model-item>
            </template>
            <IsAutoDrawForm v-model="formquery" :positionId="positionId" :platId="formquery.platId"/>
            <TableScreenSourceForm
              v-if="isAdd"
              :positionId="positionId"
              :platInfo="platInfo"
              :query="formquery"
              @changeQuery="changeCongQuery"
            />
            <OpenScreenSourceForm
              v-if="isAdd"
              :positionId="positionId"
              :platInfo="platInfo"
              :query="formquery"
              @changeQuery="changeCongQuery"
            />
            <!-- 操作系统 -->
            <!-- 联盟广告 and api广告 -->
            <a-form-model-item prop="os" v-if="platInfo.accessType === '1'">
              <m-tips
                slot="label"
                :content="`使用联盟媒体流量时，可根据要求对操作系统进行过滤，如果投放的广告位不区分安卓、iOS，可选择通用；如果区分，可对应选择安卓或iOS。`"
                :title="'操作系统'"
                :width="200"
              ></m-tips>
              <a-radio-group name="radioGroup" v-model="formquery.os">
                <a-radio :value="1"> 安卓 </a-radio>
                <a-radio :value="0"> IOS </a-radio>
                <a-radio :value="2"> 通用 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 编辑API时展示 -->
            <template v-if="sdkDisabled">
              <!-- 出价类型 -->
              <a-form-model-item prop="settleType">
                <span slot="label">出价类型</span>
                <a-radio-group name="radioGroup" v-model="formquery.settleType">
                  <a-radio :value="1"> RTB竞价 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <!-- 竞价底价策略 -->
              <a-form-model-item prop="bidfloorStrategy">
                <span slot="label">竞价底价策略</span>
                <el-radio-group v-model="formquery.bidfloorStrategy" size="medium" @change="changeSet">
                  <el-radio label="0" border>媒体优先</el-radio>
                  <el-radio label="1" border>媒体低价百分比</el-radio>
                  <el-radio label="2" border>平台自定义</el-radio>
                </el-radio-group>
              </a-form-model-item>
              <!-- 竞价底价 -->
              <a-form-model-item
                v-if="+formquery.bidfloorStrategy === 2"
                :rules="[{ required: true, validator: validatorBidfloor2, trigger: 'blur' }]"
                prop="bidfloor"
              >
                <m-tips
                  slot="label"
                  :content="`RTB竞价广告源竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
                  :title="'竞价底价'"
                  :width="200"
                ></m-tips>
                <a-input style="width: 200px" placeholder="请输入竞价底价" v-model.number.trim="formquery.bidfloor">
                  <span slot="addonAfter">￥</span>
                </a-input>
              </a-form-model-item>
              <!-- 底价百分比值 -->
              <a-form-model-item
                label="底价百分比值"
                prop="bidfloorFloatingRate"
                v-if="+formquery.bidfloorStrategy === 1"
                :rules="[{ required: true, validator: validatorBidfloorFloatingRate, trigger: 'blur' }]"
              >
                <a-input
                  style="width: 200px"
                  placeholder="请输入底价百分比值"
                  v-model.number.trim="formquery.bidfloorFloatingRate"
                >
                  <span slot="addonAfter">%</span>
                </a-input>
              </a-form-model-item>
              <!-- 媒体出价策略 -->
              <a-form-model-item prop="settleStrategy">
                <span slot="label">媒体出价策略</span>
                <el-radio-group v-model="formquery.settleStrategy" size="medium" @change="changeSet">
                  <el-radio label="0" border>默认</el-radio>
                  <el-radio label="1" border>百分比浮动</el-radio>
                  <el-radio label="2" border>平台自定义</el-radio>
                </el-radio-group>
              </a-form-model-item>
              <!-- 浮动比例 -->
              <a-form-model-item
                label="价格浮动"
                prop="floatingRate"
                v-if="+formquery.settleStrategy === 1"
                :rules="[{ required: true, validator: validatorFloatingRate, trigger: 'blur' }]"
              >
                <a-input style="width: 200px" placeholder="请输入价格浮动" v-model.number.trim="formquery.floatingRate">
                  <span slot="addonAfter">%</span>
                </a-input>
              </a-form-model-item>
              <!-- 结算底价 -->
              <a-form-model-item
                label="结算底价"
                prop="settleBidfloor"
                :rules="[{ required: true, validator: validatorSettleBidfloor, trigger: 'blur' }]"
                v-if="+formquery.settleStrategy === 2"
              >
                <a-input style="width: 200px" placeholder="请输入结算底价" v-model.number.trim="formquery.settleBidfloor">
                  <span slot="addonAfter">元</span>
                </a-input>
              </a-form-model-item>
              <!-- 上游成交策略 -->
              <a-form-model-item prop="dspSettleStrategy">
                <span slot="label">上游成交策略</span>
                <el-radio-group v-model="formquery.dspSettleStrategy" size="medium" @change="changeSet">
                  <el-radio label="0" border>默认</el-radio>
                  <el-radio label="1" border>百分比浮动</el-radio>
                  <el-radio label="2" border>一价成交</el-radio>
                </el-radio-group>
              </a-form-model-item>
              <!-- 上游成交浮动比例 -->
              <a-form-model-item
                label="上游成交浮动比例"
                v-if="+formquery.dspSettleStrategy === 1"
                prop="dspFloatingRate"
                :rules="[{ required: true, validator: validatorDspFloatingRate, trigger: 'blur' }]"
              >
                <a-input style="width: 200px" placeholder="请输入价格浮动" v-model.number.trim="formquery.dspFloatingRate">
                  <span slot="addonAfter">%</span>
                </a-input>
              </a-form-model-item>
            </template>
            <template v-if="+platInfo.accessType !== 1">
              <!-- 价格类型 -->
              <a-form-model-item prop="settleType" v-if="'settleType' in platInfo">
                <m-tips
                  slot="label"
                  v-if="platInfo.id === 2 || platInfo.id === 6"
                  :content="
                    platInfo.id === 2
                      ? `优量汇竞价必须选择服务端实时竞价(S2S Bidding)。优量汇头部竞价需要${title} SDK  2.4.2及以上版本支持。`
                      : platInfo.id === 6
                        ? `百度竞价为服务端实时竞价(S2S Bidding)。百度头部竞价需要${title} SDK 2.4.9及以上版本支持。`
                        : ``
                  "
                  :title="'价格类型'"
                  :width="200"
                ></m-tips>
                <span v-else slot="label">价格类型</span>
                <a-radio-group name="radioGroup" v-model="formquery.settleType">
                  <a-radio :value="0"> 固价 </a-radio>
                  <a-radio :value="1" v-if="+platInfo.settleType === 1 || +platInfo.settleType === 3">
                    服务端竞价
                  </a-radio>
                  <a-radio :value="2" v-if="+platInfo.settleType === 2 || +platInfo.settleType === 3">
                    客户端竞价
                  </a-radio>
                </a-radio-group>
                <a-alert
                  v-if="platInfo.id === 3 && formquery.settleType === 1"
                  style="font-size: 12px; border-radius: 5px"
                  message="使用快手联盟服务端竞价（S2S Bidding），必需填写快手联盟主账号ID，请先在广告管理-联盟广告主-界面完善相关信息。"
                  banner
                />
              </a-form-model-item>
              <!-- 排序价格 -->
              <a-form-model-item
                v-if="formquery.settleType === 0 || formquery.settleType === 1"
                :rules="[{ required: formquery.settleType === 0, validator: validatorBidfloor, trigger: 'blur' }]"
                prop="bidfloor"
              >
                <m-tips
                  slot="label"
                  v-if="formquery.settleType === 0"
                  :content="`该价格仅用于广告请求时的优先级排序，并不会回传给广告平台。如果在广告平台设置了eCPM底价，建议此处填写的价格与eCPM底价保持一致。如果未设置eCPM底价，建议填写0.01。`"
                  :title="'排序价格'"
                  :width="200"
                ></m-tips>
                <m-tips
                  slot="label"
                  v-if="formquery.settleType === 1"
                  :content="`服务端Bidding广告源竞价底价：当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。${title} SDK版本2.7.7及以上支持`"
                  :title="'竞价底价'"
                  :width="200"
                ></m-tips>
                <a-input
                  style="width: 200px"
                  placeholder="请输入排序价格"
                  @change="changeBidfloor"
                  v-model.number.trim="formquery.bidfloor"
                >
                  <span slot="addonAfter">￥</span>
                </a-input>
              </a-form-model-item>
            </template>
            <!-- dealID -->
            <a-form-model-item v-if="platInfo.id === 997" label="dealID" prop="dealid">
              <a-input placeholder="请输入dealID" v-model.trim="formquery.dealid" />
            </a-form-model-item>
            <!-- 是否自动价格 -->
            <a-form-model-item v-if="formquery.settleType === 0" prop="isAutoSorting">
              <m-tips
                slot="label"
                :content="`选择是之后，${title}将根据广告平台过去3天的平均eCPM来计算价格，需要广告源所属广告平台开通Reporting API后才可使用。当展示少于1000时，该配置不生效。`"
                :title="'是否自动价格'"
                :width="200"
              ></m-tips>
              <a-radio-group name="radioGroup" v-model="formquery.isAutoSorting">
                <a-radio value="1"> 是 </a-radio>
                <a-radio value="0"> 否 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 是否校验 -->
            <a-form-model-item v-if="+checkable === 1" label="是否校验" prop="isBlack">
              <a-radio-group name="radioGroup" v-model="formquery.isBlack" :disabled="!isAdd">
                <a-radio value="1"> 是 </a-radio>
                <a-radio value="0"> 否 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <template v-if="+platInfo.accessType !== 1">
              <!-- 价格浮动% -->
              <a-form-model-item
                v-if="+platInfo.settleType === 1 && platInfo.id !== 7 && platInfo.id !== 11"
                label="价格浮动%"
                prop="floatingRate"
              >
                <a-input-number
                  style="width: 200px"
                  placeholder="请输入价格浮动%"
                  :min="0"
                  :max="100"
                  v-model.trim="formquery.floatingRate"
                />
              </a-form-model-item>
            </template>
            <HostSpotSourceForm
              v-if="isAdd"
              :query="formquery"
              :positionId="positionId"
              :platInfo="platInfo"
              @changeQuery="changeCongQuery"
            />
            <!-- 模板类型 -->
            <a-form-model-item
              v-if="
                (positionId === 6 && platInfo.accessType === '1') ||
                  (positionId === 10 && platInfo.accessType === '1' && formquery.adStyle === 1)
              "
              label="模版类型"
              prop="templateTypeId"
            >
              <a-select placeholder="请选择模版类型" v-model="formquery.templateTypeId">
                <a-select-option v-for="item in templateTypeList" :key="item.id" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 左右边距 -->
            <a-form-model-item
              v-if="
                (positionId === 6 && platInfo.accessType === '1') ||
                  (positionId === 10 && platInfo.accessType === '1' && formquery.adStyle === 1)
              "
              label="左右边距"
              prop="lrMargin"
            >
              <a-input-number style="width: 200px" placeholder="请输入左右边距" v-model.trim="formquery.lrMargin" />
            </a-form-model-item>
            <!-- 上下边距 -->
            <a-form-model-item
              v-if="
                (positionId === 6 && platInfo.accessType === '1') ||
                  (positionId === 10 && platInfo.accessType === '1' && formquery.adStyle === 1)
              "
              label="上下边距"
              prop="tbMargin"
            >
              <a-input-number style="width: 200px" placeholder="请输入上下边距" v-model.trim="formquery.tbMargin" />
            </a-form-model-item>
            <template v-if="!sdkDisabled">
              <!-- 应用名称 -->
              <a-form-model-item v-if="platInfo.accessType === '1'" label="上报应用名称" prop="appnameReplace">
                <a-input placeholder="请输入应用名称" v-model.trim="formquery.appnameReplace" />
              </a-form-model-item>
              <!-- 应用版本号 -->
              <a-form-model-item v-if="platInfo.accessType === '1'" label="上报应用版本号" prop="versionReplace">
                <a-input placeholder="请输入应用版本号" v-model.trim="formquery.versionReplace" />
              </a-form-model-item>
              <!-- 应用包名 -->
              <a-form-model-item v-if="platInfo.accessType === '1'" label="上报应用包名" prop="bundleReplace">
                <a-input placeholder="请输入应用包名" v-model.trim="formquery.bundleReplace" />
              </a-form-model-item>
            </template>
            <PlatAPISourceForm
              v-if="isAdd && platInfo.accessType === '1'"
              :positionId="positionId"
              :platInfo="platInfo"
              :query="formquery"
              @changeQuery="changeCongQuery"
            />
            <!-- 状态 -->
            <a-form-model-item v-if="!isAdd" label="状态" prop="sts">
              <a-radio-group name="radioGroup" v-model="formquery.sts">
                <a-radio value="A"> 启动 </a-radio>
                <a-radio value="S"> 暂停 </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
          <!-- settleType Number -->
          <!-- id Number -->
          <!-- platId Number -->
          <!-- accessType String -->
          <!-- isAutoDrawing String -->
          <!-- positionId Number -->
          <!-- templateIds String[] -->
        </div>
      </div>
    </a-spin>
    <SDKAccount
      v-bind="$attrs"
      v-if="addAccountVisible"
      :visible="addAccountVisible"
      :platInfo="platInfo"
      :accountInfo="accountInfo"
      :isAddAcount="isAddAcount"
      @modalCancel="addAccountVisible = false"
      :query="query"
      @addNewAccount="getPlatAccounts"
      @editAccount="getPlatAccounts(2)"
    />
  </a-modal>
</template>

<script>
import {
  getPlatAccounts,
  getCreativeTemplateList,
  gettemplateTypeList,
  checkPlatPlaceId,
  addSource,
  updateSource,
  appBindPlat,
  updatePlatAppId,
  getPlatFormList,
  platformLinkList
} from '@/api/aggregate'
import BiddingSvg from '@/assets/icons/bidding.svg?inline'
import AutoSvg from '@/assets/icons/Auto.svg?inline'
import { getAdGroupList, getAdPlanList } from '@/api/advertisementStep'
// import AddAccountModal from '../addAccountModal'
import { copy } from '@/utils/string'
import { mapState } from 'vuex'
import {
  csjadTypeList,
  ylhadTypeList,
  ksadTypeList,
  bddTypeList,
  otadStyleList,
  bdadStyleList,
  ksadStyleList
} from './list'
import { platPlaceLabel, showIsAutoDrawing, showAppKey } from '@/utils/autoLabel'
import md5 from 'js-md5'
import getList from '@/mixins/getList'
import { initAutoCreateFormDefault } from '@/utils/initSourceAutoCreateForm'
import BaiduAutoCreateForm from './BaiduAutoCreateForm.vue'
import TableScreenSourceForm from '@/components/sourceModal/TableScreenSourceForm'
import OpenScreenSourceForm from '@/components/sourceModal/OpenScreenSourceForm'
import HostSpotSourceForm from '@/components/sourceModal/HotSpotSourceForm'
import PlatAPISourceForm from '@/components/sourceModal/PlatAPISourceForm'
import IsAutoDrawForm from '@/views/aggregate/Config/components/sourceModal/IsAutoDrawForm'
const SDKAccount = () => import(/* webpackChunkName: "dialog" */ './SDKAccount')

export default {
  components: { SDKAccount, BiddingSvg, AutoSvg, BaiduAutoCreateForm, TableScreenSourceForm, HostSpotSourceForm, OpenScreenSourceForm, PlatAPISourceForm, IsAutoDrawForm },
  mixins: [getList],
  name: 'SourceModal',
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      YLHadCrtTypeListDisabled: false,
      // 优量汇广告场景选择
      sceneKey: 1,
      // 账户绑定参数
      bindquery: {},
      // 广告源参数
      formquery: {
        appId: '',
        platId: '',
        platAccountId: undefined,
        isAutoCreate: 0,
        scene: undefined,
        crtType: undefined,
        adCrtTypeList: [],
        templateIdList: [],
        imgSizeList: [],
        videoSizeList: [],
        countdownShow: 0,
        rewardedType: undefined,
        rewardedNum: 0,
        orientation: 1,
        adRolloutSize: 1,
        skipDuration: 5,
        videoVoiceControl: 1,
        closeButtonType: 'S',
        platAppId: '',
        appKey: '',
        name: '',
        platPlaceId: undefined,
        ext: '',
        templateIds: [],
        isAutoDrawing: '0',
        settleType: 0,
        bidfloor: null,
        dealid: '',
        isAutoSorting: '0',
        isBlack: '0',
        floatingRate: 0,
        hotspot: 'S',
        hotspotType: 1,
        sensitivity: 50,
        templateTypeId: undefined,
        lrMargin: 0,
        tbMargin: 0,
        isDownload: 'S',
        os: 2,
        sts: 'A',
        appnameReplace: undefined,
        versionReplace: undefined,
        bundleReplace: undefined,
        planId: undefined,
        adScene: undefined,
        adType: 1,
        adStyle: 1,
        bidfloorStrategy: '0',
        settleStrategy: '0',
        dspSettleStrategy: '0',
        bidfloorFloatingRate: 0,
        settleBidfloor: 1,
        dspFloatingRate: 1,
        isFullScreen: 0,
        cpCloseButton: 'S',
        cpDisplayCountdown: 1,
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      },
      rules: {},
      // 广告平台列表
      platList: [],
      allPlatList: [],
      // 当前广告平台（绑定左侧menu）
      currentPlat: [],
      // 左侧menu搜索字段
      searchKey: '',
      // 当前选择的广告平台
      platInfo: {
        name: '',
        id: '',
        accessType: ''
      },
      // 账户列表
      accountList: [],
      // 添加账户弹窗
      addAccountVisible: false,
      isAddAcount: true,
      // 素材模板列表
      templeteList: [],
      hasGetTemplateList: false,
      // 模板类型列表
      templateTypeList: [],
      // 广告计划列表
      adPlanList: [],
      // 应用ID是否正在修改
      allowEdit: false,
      // 修改之前的应用ID
      editPlatAppId: '',
      adGroupList: [], // 广告组列表
      isLoading: false
    }
  },
  props: {
    // 当前广告位的广告样式ID
    positionId: {
      default: '',
      type: [Number, String]
    },
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    groupName: {
      default: '',
      type: String
    },
    testList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        // 编辑状态下初始化广告源参数
        if (this.isAdd) {
          // this.allowEdit = true
          return
        }
        this.formquery = JSON.parse(JSON.stringify(val))
        this.formquery.adType = Number(this.formquery.adType)
        this.formquery.adStyle = Number(this.formquery.adStyle)
        // UI组件绑定String格式
        this.formquery.templateIds && (this.formquery.templateIds = this.formquery.templateIds.map((item) => '' + item))
      },
      deep: true,
      immediate: true
    },
    // 应用id编辑不允许输入汉字和空格
    'formquery.platAppId': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.platAppId = newValue.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      }
    },
    // 名称不允许输入空格
    'formquery.name': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.name = newValue.replace(/\s+/g, '')
      }
    },
    // 广告源ID不允许输入空格和汉字
    'formquery.platPlaceId': {
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        this.formquery.platPlaceId = newValue.replace(/[\u4e00-\u9fa5\s]+/g, '')
      }
    }
  },
  computed: {
    plat2TemplateList () {
      if (+this.platInfo.id === 2 && (+this.formquery.scene === 4 || +this.formquery.adType === 4)) {
        return [
          { id: '1', name: '16:9 图片' },
          { id: '2', name: '16:9 视频' },
          // { id: '3', name: '3:2 三小图' },
          { id: '4', name: '9:16 图片' },
          { id: '5', name: '9:16 视频' }
          // { id: '6', name: '20:3 图片' }
        ]
      }
      if (+this.platInfo.id === 2 && (+this.formquery.scene === 1 || +this.formquery.adType === 1)) {
        return [
          { id: '1', name: '16:9 图片' },
          { id: '2', name: '16:9 视频' },
          { id: '3', name: '3:2 三小图' },
          { id: '4', name: '9:16 图片' },
          { id: '5', name: '9:16 视频' },
          { id: '6', name: '20:3 图片' }
        ]
      }
      return [
        { id: '1', name: '16:9 图片' },
        { id: '2', name: '16:9 视频' },
        { id: '3', name: '3:2 三小图' },
        { id: '4', name: '9:16 图片' },
        { id: '5', name: '9:16 视频' }
        // { id: '6', name: '20:3 图片' }
      ]
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity
    }),
    sdkDisabled () {
      const platId = this.formquery.platId
      return (platId === 5 || platId > 900) && platId < 10000 && platId !== 1019 && !this.isAdd
    },
    // 快手广告平台下是否有视频素材
    isVideoTypeKS () {
      let flag = false
      if (this.formquery.adCrtTypeList.includes(1) || this.formquery.adCrtTypeList.includes(2)) {
        flag = true
      }
      return flag
    },
    templateIdListForCSJ6 () {
      if (this.formquery.adCrtTypeList[0] === 2) {
        return [
          { id: '1', name: '上⽂下图' },
          { id: '2', name: '上图下⽂' },
          { id: '3', name: '⽂字浮层' },
          { id: '4', name: '竖版' }
        ]
      } else {
        return [
          { id: '1', name: '上⽂下图' },
          { id: '2', name: '上图下⽂' },
          { id: '3', name: '⽂字浮层' },
          { id: '4', name: '竖版' },
          { id: '5', name: '左图右⽂' },
          { id: '6', name: '左⽂右图' },
          { id: '7', name: '三图' }
        ]
      }
    },
    templateIdListForKS6 () {
      // 1:竖版视频 2:横版视频 5: 竖版图片 6: 横版图片
      const obj3 = [
        { id: '1', name: '大图(支持横版图片/视频)' },
        { id: '2', name: '左文右图(支持横版图片)' },
        { id: '3', name: '左图右文(支持横版图片)' },
        { id: '4', name: '上文下图(支持横版图片/视频)' },
        { id: '5', name: '上图下文(支持横版图片/视频)' },
        { id: '14', name: '三图(支持横版图片)' },
        { id: '15', name: '三图组合(支持横版图片)' },
        { id: '16', name: '橱窗(支持横版图片)' }
      ]
      const obj = [
        { id: '17', name: '上文下图(支持竖版图片/视频)' },
        { id: '18', name: '上图下文(支持竖版图片/视频)' },
        { id: '19', name: '大图（支持竖版图片/视频' }
      ]
      const obj1 = [
        { id: '1', name: '大图(支持横版图片/视频)' },
        { id: '4', name: '上文下图(支持横版图片/视频)' },
        { id: '5', name: '上图下文(支持横版图片/视频)' }
      ]
      const list = {
        1: obj,
        5: obj,
        2: obj1,
        6: obj3
      }
      let arr = []
      this.formquery.adCrtTypeList.forEach((item) => {
        arr = [...arr, ...list[item]]
      })
      return [...new Map(arr.map((item) => [item.id, item])).values()]
    },
    showTemplateIds () {
      const id = this.platInfo.id
      // 需要显示素材模板的广告平台
      const showPlatList = [
        995, 989, 1020, 987, 988, 997, 998, 1011, 1014, 1025, 1026, 1028, 1007, 1027, 1001, 1024, 1031, 1032, 1034
      ]
      return showPlatList.includes(id)
    },
    rewardedTypeList () {
      if (this.positionId === 5 && +this.platInfo.id === 3) {
        return [
          { value: 1, name: '虚拟金币' },
          { value: 2, name: '积分' },
          { value: 3, name: '生命' },
          { value: 4, name: '体力值' },
          { value: 5, name: '通关机会' },
          { value: 6, name: '新关卡机会' },
          { value: 7, name: '阅读币' },
          { value: 8, name: '新章节（小说类）' },
          { value: 9, name: '观影币' },
          { value: 10, name: '观看机会' },
          { value: 11, name: '其他' }
        ]
      } else {
        return []
      }
    },
    crtTypeList () {
      if (this.positionId === 1 && +this.platInfo.id === 2) {
        return [
          { name: '开屏', value: 'FLASH' },
          { name: '开屏V+', value: 'FLASH_V_PLUS' }
        ]
      } else if (this.positionId === 5 && +this.platInfo.id === 2) {
        return [
          { name: '激励浏览+激励视频', value: 'ALL_DIRECTION' },
          { name: '激励浏览', value: 'IMAGE' },
          { name: '激励视频', value: 'VIDEO' }
        ]
      } else {
        return []
      }
    },
    sceneList () {
      if (this.positionId === 1 && +this.platInfo.id === 2) {
        return [
          { name: '冷启动', value: 'FLASH_CODE' },
          { name: '热启动', value: 'FLASH_HOT' },
          { name: '不区分冷热启动', value: 'FLASH_OTHER' }
        ]
      } else if (this.positionId === 5 && +this.platInfo.id === 2) {
        return [
          { name: '登录奖励：每日登录、打卡奖励', value: 'REWARDED_APP_LOGIN' },
          { name: '过关奖励：过关、通关加倍', value: 'REWARDED_APP_PASS' },
          { name: '线索提示：获得提示、生命复/恢复', value: 'REWARDED_APP_TIPS' },
          { name: '任务奖励：完成应用内指定任务', value: 'REWARDED_APP_TASK' },
          { name: '价值奖励：限时解锁、功能试用', value: 'REWARDED_APP_TRY' },
          { name: '兑换奖励：兑换应用内虚拟货币、付费功能', value: 'REWARDED_APP_COINS' },
          { name: '其他奖励：其他类型奖励', value: 'REWARDED_APP_OTHER' }
        ]
      } else {
        return []
      }
    },
    accountInfo () {
      const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
      return account || null
    },
    // 测试分组名
    testName () {
      const currentTest = this.testList.find((item) => item.testId === this.query.testId)
      return currentTest ? currentTest.groupName : ''
    },
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    positionName () {
      const p = this.positionList.find((item) => +item.id === +this.positionId)
      if (p) {
        return p.name
      } else {
        return ''
      }
    },
    // 搜索、显示的平台列表
    fliterPlatList () {
      return this.platList.filter((item) => item.name.includes(this.searchKey))
    },
    adTypeList () {
      const adTypeObj = {
        1: csjadTypeList,
        2: ylhadTypeList,
        3: ksadTypeList,
        6: bddTypeList
      }
      return adTypeObj[this.formquery.platId]
    },
    adStyleList () {
      const formquery = this.formquery
      let list = []
      // 穿山甲 广告位类型选择贴片时 ｜｜ 优量汇 广告位类型选择沉浸式视频流时
      const type =
        (formquery.adType === 3 && formquery.platId === 1) || (formquery.adType === 4 && formquery.platId === 2)
      list = type ? (list = otadStyleList.filter((item) => item.id === 2)) : otadStyleList
      // 百度
      if (formquery.platId === 6) {
        list = bdadStyleList
      }
      // 快手
      if (formquery.platId === 3 && formquery.adType === 2) {
        list = ksadStyleList
      }
      return list
    },
    showAdType () {
      const show =
        (this.formquery.platId === 6 && (this.formquery.adType === 2 || this.formquery.adType === 3)) ||
        this.positionId !== 10
      return !show
    }
  },
  async mounted () {
    await this.platformLinkList()
    await this.getPositionList()
    if (!this.isAdd) {
      await this.getAllPlatList()
      this.platInfo = this.allPlatList.find((item) => item.id === this.formquery.platId)
      this.getPlatAccounts(2)
      this.platInfo.id === 1019 && this.changePlan(this.formquery.adPlanId)
      this.platInfo.id === 1019 && (this.formquery.planId = this.formquery.adPlanId)
    } else {
      await this.getAllPlatList()
      this.formquery.platId = this.query.platId
      this.formquery.platAppId = this.query.platAppId ? this.query.platAppId : ''
      if (!this.query.platAppId) {
        this.allowEdit = true
      }
      this.formquery.platAccountId = this.query.accountId
      this.formquery.accountName = this.query.accountName
      this.platInfo = this.allPlatList.find((item) => item.id === this.formquery.platId)
      this.formquery.name = `${this.platInfo.name}-${this.positionName}`
      this.getPlatAccounts(2)
      this.platInfo.id === 1019 && this.changePlan(this.formquery.adPlanId)
      this.platInfo.id === 1019 && (this.formquery.planId = this.formquery.adPlanId)
    }
    this.formquery.appId = this.query.appId
    this.getCreativeTemplateList()
    this.gettemplateTypeList()
    this.getAdPlanList()
  },
  methods: {
    changeAutoDraw (e) {
      this.formquery.closeButtonType = +e.target.value === 1 ? 'A' : 'S'
    },
    changeCongQuery (e) {
      this.formquery = JSON.parse(JSON.stringify(e))
    },
    changeFormQuery (prop, val) {
      this.formquery[prop] = val
    },
    async getAllPlatList () {
      const { data = [] } = await platformLinkList()
      this.allPlatList = data
    },
    changeSet (data) {
      this.$refs.ruleForm.clearValidate()
    },
    validatorBidfloor2 (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入竞价底价！'))
      } else if (value < 0.01) {
        return callback(new Error('竞价底价必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorBidfloorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入底价百分比值！'))
      } else if (value < 0.01) {
        return callback(new Error('底价百分比值必须大于0.01！'))
      } else if (value > 100) {
        return callback(new Error('底价百分比值必须小于100！'))
      } else {
        return callback()
      }
    },
    validatorFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else if (value < 0.01) {
        return callback(new Error('浮动比例必须大于0.01！'))
      } else if (value > 100) {
        return callback(new Error('浮动比例必须小于100！'))
      } else {
        return callback()
      }
    },
    validatorSettleBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入结算底价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    validatorDspFloatingRate (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else if (value < 1) {
        return callback(new Error('浮动比例必须大于等于1！'))
      } else if (value > 100) {
        return callback(new Error('浮动比例必须小于100！'))
      } else {
        return callback()
      }
    },
    // 选择广告位类型
    changeAdType (e) {
      this.formquery.isAutoCreate = 0
      e === 4 && (this.formquery.adStyle = 2)
      e !== 4 && (this.formquery.adStyle = 1)
      e !== 4 && this.platInfo.id === 1 && (this.formquery.adStyle = 2)
    },
    // 切屏样式下，热区开启默认摇一摇
    startShake (e) {
      if (e.target.value === 'A' && this.positionId === 3 && this.platInfo.id === 1019) {
        this.formquery.hotspotType = 2
      }
    },
    changeHotspot (e) {
      if (e.target.value === 'A' && this.positionId === 3) {
        this.formquery.hotspotType = 2
      } else {
        this.formquery.hotspotType = 1
      }
    },
    changeHotspotType (e) {
      this.formquery.hotspotType = e.target.value
      this.$forceUpdate()
    },
    changeYLHTemplateIdList (e) {
      // 是否有视频
      let flag = false
      const arr = ['1', '2', '5', '7']
      e.forEach((item) => {
        if (arr.includes(item)) {
          flag = true
        }
      })
      if (flag) {
        this.YLHadCrtTypeListDisabled = true
        this.formquery.adCrtTypeList = ['IMAGE', 'VIDEO']
      } else {
        this.YLHadCrtTypeListDisabled = false
        this.formquery.adCrtTypeList = ['IMAGE']
      }
    },
    ChangeKSPositionId6AdCrtTypeList (e) {
      if (!e.includes(5) && !e.includes(6)) {
        const arr = [2, 3, 14, 15, 16]
        this.formquery.templateIdList = this.formquery.templateIdList.filter((item) => !arr.includes(item))
      }
    },
    initAutoCreateForm () {
      this.$refs.ruleForm.clearValidate(['platPlaceId'])
      this.formquery.scene = undefined
      this.formquery.crtType = undefined
      this.formquery.adCrtTypeList = []
      this.formquery.templateIdList = []
      this.formquery.countdownShow = 0
      this.formquery.rewardedType = undefined
      this.formquery.rewardedNum = 0
      this.formquery.isAutoDrawing = '0'
      this.initAutoCreateFormDefault(this.platInfo, this.formquery, this.positionId)
    },
    initAutoCreateFormDefault,
    changeCrtType (e) {
      if (e.target.value === 'FLASH') {
        this.formquery.adCrtTypeList = ['FLASH_IMAGE_V']
      } else if (e.target.value === 'FLASH_V_PLUS') {
        this.formquery.adCrtTypeList = ['FLASH_VIDEO_LONG', 'FLASH_IMAGE_V', 'FLASH_VIDEO_V']
      } else {
        this.formquery.adCrtTypeList = []
      }
    },
    handleClickAddAcount () {
      this.isAddAcount = true
      this.addAccountVisible = true
    },
    handleClickEditAcount () {
      this.isAddAcount = false
      this.addAccountVisible = true
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    validatorBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入排序价格！'))
      } else if (value < 0.01) {
        return callback(new Error('排序价格必须大于等于0.01！'))
      } else {
        return callback()
      }
    },
    // 处理应用图标
    getLogoUrl (url) {
      if (url) {
        return url
      } else {
        return 'https://creative.medproad.com/medpro/imgs/logo.png'
      }
    },
    platPlaceLabel,
    showIsAutoDrawing,
    showAppKey,
    async changePlan (id) {
      const resp = await getAdGroupList([id])
      this.adGroupList = resp.data || []
    },

    // 应用ID编辑
    changeAllowEdit () {
      this.allowEdit = true
      // 保存原始应用ID
      this.editPlatAppId = this.formquery.platAppId
    },
    // 价格输入限制
    changeBidfloor (e) {
      if (!+e.target.value && +e.target.value !== 0) {
        this.formquery.bidfloor = undefined
      }
      if (e.target.value < 0) {
        this.formquery.bidfloor = undefined
      }
    },
    // 获取广告计划列表
    getAdPlanList () {
      getAdPlanList().then((resp) => {
        this.adPlanList = resp.data || []
        this.adPlanList.forEach((item) => {
          item.id = '' + item.id
        })
      })
    },
    // 获取模版类型列表
    async gettemplateTypeList () {
      const resp = await gettemplateTypeList()
      this.templateTypeList = resp.data || []
    },
    // 素材模板选择事件
    handleChangeTemplete (e) {
      this.formquery.templateIds = e
    },
    // 获取素材模板
    async getCreativeTemplateList () {
      const resp = await getCreativeTemplateList()
      this.templeteList = resp.data.map((item) => {
        return {
          name: item.name,
          id: item.id
        }
      })
      this.hasGetTemplateList = true
    },
    // 复制方法的调用和反馈
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 参数JSON的校验函数
    extValidator (rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入参数'))
      } else {
        try {
          if (JSON.parse(value)) return callback()
        } catch (error) {
          return callback(new Error('请输入正确的JSON格式'))
        }
      }
    },
    // 获取账户列表
    /**
     * type 默认为1，即需要账户id选中第一个，传入其它值则不会默认选择第一项（编辑模式）
     * @param {*} type
     */
    async getPlatAccounts (type = 1) {
      const resp = await getPlatAccounts({
        platId: this.formquery.platId,
        appId: this.query.appId
      })
      this.accountList = resp.data || []
      // this.accountInfo = this.accountList.find((item) => item.id === this.formquery.platAccountId)
      if (type === 1) {
        this.accountList.length > 0 && (this.formquery.platAccountId = this.accountList[0].id)
      }
    },
    // 获取广告平台列表
    async platformLinkList () {
      this.isLoading = true
      const query = {
        ad: this.allRoutes.includes('directpassenger'),
        waterfall: true,
        accessTypeList: [0, 2],
        placeId: this.query.placeId
      }
      const resp = await getPlatFormList(query)
      const arr = resp.data || []
      const already = arr.filter((item) => +item.isUsed === 1)
      const haveNot = arr.filter((item) => +item.isUsed !== 1)
      const obj = {
        name: '未创建广告源的广告平台',
        type: 'notCreat',
        id: 'notCreat'
      }
      this.platList = [...already, ...[obj], ...haveNot] || []
      // 添加模式选中第一项并获取当前广告平台下的账户
      if (this.isAdd) {
        if (already.length === 0) {
          this.platInfo = this.platList[1]
          this.currentPlat[1] = this.platInfo.id
        } else {
          this.platInfo = this.platList[0]
          this.currentPlat[0] = this.platInfo.id
        }
        this.formquery.platId = this.platInfo.id
        // 仓渊ADX、自定义广告平台的广告源ID 应用ID随机生成
        if (+this.platInfo.id === 5 || +this.platInfo.accessType === 2) {
          this.formquery.platPlaceId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
          this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
        } else {
          this.formquery.platPlaceId = undefined
          // this.formquery.platAppId = undefined
        }
        // 爱奇艺、拼多多、同程、瑞狮自动生成应用ID
        if (
          +this.platInfo.id === 1001 ||
          +this.platInfo.id === 1027 ||
          +this.platInfo.id === 1024 ||
          +this.platInfo.id === 1031
        ) {
          this.formquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
        }
        // this.getPlatAccounts().then(() => {
        //   this.changePlatAccountId(this.formquery.platAccountId)
        // })
      }
      this.isLoading = false
    },
    handleCancel () {
      this.addSourceVisible = false
    },
    // 确认按钮点击事件
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (
            (this.positionId === 1 || this.positionId === 5) &&
            this.isAdd &&
            +this.platInfo.id === 2 &&
            this.formquery.isAutoCreate
          ) {
            this.formquery.platPlaceId = undefined
          }
          // 编辑、添加广告源校验
          const checkquery = {
            groupId: this.query.groupId,
            platPlaceId: this.formquery.platPlaceId,
            placeId: this.query.placeId,
            platId: this.formquery.platId
          }
          if (!this.isAdd) {
            checkquery.id = this.formquery.id
          }
          // 1. 添加前校验广告源ID
          // 2. 添加前绑定账号
          // 3. 若是修改模式需要在修改应用ID时调用修改应用ID接口
          // 4. 调用添加广告源接口
          const res = await checkPlatPlaceId(checkquery)
          if (res.code === 10001) {
            this.$confirm({
              title: '提示',
              content: res.msg,
              onOk: () => {
                this.handleAddOrEdit()
              }
            })
          } else if (res.code === 200) {
            this.handleAddOrEdit()
          }
        } else {
          return false
        }
      })
    },
    // 修改账户事件
    changePlatAccountId (e) {
      const account = this.accountList.find((item) => item.id === e)
      // 仓渊ADX、拼多多、同程、爱奇艺、瑞狮修改账户时，由于有默认生成的应用ID，应用ID不需要变化
      if (account && account.platAppId) {
        // this.formquery.platAppId = account.platAppId
        this.allowEdit = false
      } else if (
        this.platInfo.id !== 5 &&
        this.platInfo.id !== 1001 &&
        this.platInfo.id !== 1027 &&
        this.platInfo.id !== 1024 &&
        this.platInfo.id !== 1031 &&
        +this.platInfo.accessType !== 2
      ) {
        // this.formquery.platAppId = undefined
        this.allowEdit = true
      }
    },
    // 绑定账号
    async appBindPlat () {
      const currentAccount = this.accountList.find((item) => this.formquery.platAccountId === item.id)
      let type
      if (currentAccount && !currentAccount.platAppId) {
        type = 0
      } else {
        type = 1
      }
      let oldPlatAppId = ''
      if (!this.isAdd) {
        oldPlatAppId = this.sourceInfo.platAppId ? this.sourceInfo.platAppId : undefined
      } else {
        const account = this.accountList.find((item) => item.id === this.formquery.platAccountId)
        oldPlatAppId = account && account.platAppId
      }
      this.bindquery = {
        appId: this.query.appId,
        placeId: this.query.placeId,
        appKey: this.formquery.appKey,
        platId: this.formquery.platId,
        platAccountId: this.formquery.platAccountId,
        platAppId: this.formquery.platAppId,
        oldPlatAppId: oldPlatAppId,
        type
      }
      if (
        (+this.platInfo.id === 5 ||
          +this.platInfo.id === 1027 ||
          +this.platInfo.id === 1024 ||
          +this.platInfo.id === 1031 ||
          +this.platInfo.id === 1001 ||
          +this.platInfo.accessType === 2) &&
        !this.bindquery.platAppId
      ) {
        this.bindquery.platAppId = md5('' + new Date().getTime() + Math.random()).slice(0, 10)
      }
      return appBindPlat(this.bindquery)
    },
    // 添加或编辑广告源
    async handleAddOrEdit () {
      this.formquery.groupId = this.query.groupId
      this.formquery.placeId = this.query.placeId
      this.formquery.testId = this.query.testId
      this.formquery.position = this.positionId
      // 绑定账号
      this.appBindPlat().then(async (res) => {
        if (res.code === 400) {
          return
        }
        // 200
        let editRes
        // 提交前先判断是否修改了应用ID
        if (this.editPlatAppId && this.editPlatAppId !== this.formquery.platAppId) {
          // 若修改了应用ID则调用修改应用ID接口
          editRes = await updatePlatAppId({
            appId: this.query.appId,
            platId: this.platInfo.id,
            platAccountId: this.formquery.platAccountId,
            platAppId: this.formquery.platAppId
          })
        }
        // 添加
        if (this.isAdd) {
          const resp = await addSource(this.formquery)
          if (resp.code === 200) {
            this.$message.success('添加成功!')
            this.$emit('changeSource')
            this.addSourceVisible = false
          } else if (resp.code === 10002) {
            this.$confirm({
              title: '提示',
              content: resp.msg,
              onOk () {},
              cancelText: '取消',
              onCancel () {}
            })
          }
        } else {
          // 编辑
          // 若修改应用ID成功，则继续完成编辑操作
          if ((editRes && editRes.code === 200) || !editRes) {
            const resp = await updateSource(this.formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeSource')
              this.addSourceVisible = false
            }
          } else {
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.source_modal .ant-modal-body {
  padding: 10px 0 10px 10px;
  position: relative;
  background: #eee;
}
</style>

<style lang="less" scoped>
.APIItem {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  height: 40px;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 170px;
  .item1 {
    padding: 0 5px;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    .itemPlatName {
      padding: 0 15px;
    }
  }
  .item2 {
    padding: 0 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .itemPlatName {
      padding: 0 15px;
    }
  }
}
.source_wrapper {
  top: 0;
  left: 0;
  height: 60vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .source_wrapper_plat {
    border-radius: 5px;
    background-color: #fff;
    width: 240px;
    position: absolute;
    height: calc(100% - 0px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .plat_search {
      border-bottom: 1px solid #ccc;
      padding: 20px;
    }
    .plat_list_wrapper {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .menu_plat {
        .menu_plat_item {
          margin: 0;
          border-bottom: 1px solid #ccc;
          border-left: 3px solid #fff;
          display: flex;
          justify-content: center;
          position: relative;
          div {
            display: flex;
            justify-content: flex-start;
          }
          &.ant-menu-item-selected {
            border-left: 3px solid;
          }
        }
        .notCreat {
          height: 50px;
          margin: 0;
          border-top: 12px solid #ebebeb;
          font-weight: 500;
          cursor: default;
          border-bottom: 1px solid #ccc;
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
  .source_wrapper_form {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep {
      .el-radio.is-bordered {
        margin-right: 0px;
      }
      .el-radio.is-bordered.is-checked {
        background: fade(@primary-color, 10%);
        border-radius: 5px;
        border: 1px solid fade(@primary-color, 30%);
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: @primary-color;
      }
      .el-radio__input {
        display: none;
      }
    }
  }
}
.iconSvg {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}
.autoIconsvg {
  top: 0;
  right: 25px;
  text-align: center;
  position: absolute;
  margin-left: 15px;
  margin-top: 3px;
  width: 30px;
  height: 30px;
  color: blue;
}
.BiddingIconSvg {
  top: 11px;
  right: 0;
  text-align: center;
  position: absolute;
  margin-left: 10px;
  width: 13px;
  height: 13px;
  color: rgb(255, 68, 0);
}
.leftBox {
  width: 180px;
  height: 100%;
  .icon-logo {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
    overflow: hidden;
    min-width: 42px;
    margin-right: -14px;
    img {
      height: 20px;
      width: 20px;
      border-radius: 10px;
    }
  }
}
.ant-popover-inner-content {
  padding: 1px 16px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
