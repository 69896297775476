<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-12 15:11:27
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-20 09:52:39
 * @FilePath: /mediatom-web/src/components/sourceModal/SlotIdForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="slotId-form-container">
    <a-form-model-item prop="slotId" v-if="showSlotId(platId)" :label="getSlotIdLabel(platId).label">
      <a-input v-model.trim="formquery.slotId" :placeholder="`请输入${getSlotIdLabel(platId).placeholder}`" />
    </a-form-model-item>
    <a-form-model-item
      v-if="+platId === 1032 && +positionId === 3"
      label="模板"
      ref="slotId"
      prop="slotId"
      :rules="[{ required: true, message: '请选择模板', trigger: 'change' }]"
    >
      <a-select placeholder="请选择模版" v-model="formquery.slotId">
        <a-select-option value="1-1">一图一文</a-select-option>
        <a-select-option value="1-2">两图一文</a-select-option>
        <a-select-option value="1-3">三图一文</a-select-option>
        <a-select-option value="1-4">一图一文一摘要</a-select-option>
        <a-select-option value="1-5">1图1文1摘要1品牌头像1品牌名称</a-select-option>
        <a-select-option value="2-1">1视频1标 题1封面图</a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>

<script>
import { showSlotId, getSlotIdLabel } from '@/utils/autoLabel'
export default {
  data () {
    return {
      formqueryTemp: {
        slotId: undefined
      }
    }
  },
  created () {
    this.$set(this.formquery, 'slotId', this.query.slotId || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    platId: {
      default: 0,
      type: [Number, String]
    },
    positionId: {
      default: 0,
      type: [Number, String]
    }
  },
  methods: {
    showSlotId,
    getSlotIdLabel
  }
}
</script>

<style lang="less" scoped>
.number-input {
  display: flex;
  align-items: center;
  .input {
    width: 100px;
  }
  .suffix {
    display: inline-block;
    border-top: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    border-left: 0px;
    border-radius: 0 3px 3px 0;
    background-color: #fafafa;
    text-align: center;
    height: 32px;
    line-height: 32px;
    width: 40px;
  }
}
</style>
