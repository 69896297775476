<template>
  <a-modal
    v-model="addaccountVisible"
    :title="isAddAcount ? '创建新账户' : '配置账号'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="add_account_modal"
    :width="700"
  >
    <div class="add_account_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <a-form-model-item label="广告平台">{{ platInfo.name }}</a-form-model-item>
        <a-form-model-item
          label="账户名称"
          prop="name"
          :rules="[{ required: true, message: '请输入账户名称', trigger: 'blur' }]"
        >
          <a-input placeholder="输入名称以区分不同账号" v-model.trim="formquery.name" />
        </a-form-model-item>
        <a-form-model-item
          v-if="platInfo.accessType !== '2' && (platInfo.id === 5 || (platInfo.id >= 900 && platInfo.id !== 989))"
          label="请求环境"
          prop="isDebug"
        >
          <a-radio-group name="radioGroup" v-model="formquery.isDebug">
            <a-radio value="1"> 测试 </a-radio>
            <a-radio value="0"> 正式 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :key="isAutoCreateKey" v-if="showIsAutoCreate(platInfo.id)" prop="isAutoCreate">
          <m-tips
            slot="label"
            :content="`开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在【${
              +platInfo.id === 1 ? '穿山甲' : +platInfo.id === 2 ? '优量汇' : +platInfo.id === 3 ? '快手' : ''
            }】后台创建【${
              +platInfo.id === 1 ? '代码位' : +platInfo.id === 2 ? '广告位' : +platInfo.id === 3 ? '广告位' : ''
            }】`"
            title="自动创建广告源"
            :width="200"
          ></m-tips>
          <a-radio-group
            @change="isAutoCreateKey = '' + new Date().getTime()"
            name="radioGroup"
            v-model="formquery.isAutoCreate"
          >
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="0"> 否 </a-radio>
          </a-radio-group>
          <a-alert
            v-if="+platInfo.id === 1 && +formquery.isAutoCreate"
            style="font-size: 12px; margin-top: 10px; border-radius: 5px"
            message="️注意：使用穿山甲自动创建代码位功能，需要在穿山甲后台开通【应用代码位管理API】相关权限；"
            banner
          />
        </a-form-model-item>
        <AccountCommonForm v-model="formquery" :currentPlat="platInfo"/>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { addPlatAccount } from '@/api/aggregate'
import { updatePlat } from '@/api/advertisement'
import { mapState } from 'vuex'
import {
  showIsAutoCreate,
  showOpenApiStatus
} from '@/utils/autoLabel'
import AccountCommonForm from '@/components/AccountModal/CommonForm'
export default {
  name: 'AddAccountModal',
  components: { AccountCommonForm },
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        platId: '',
        name: '',
        mid: '',
        keyId: '',
        signKey: '',
        isDebug: '1',
        requestUrl: '',
        openApiStatus: 1,
        accountId: '',
        accessKey: '',
        secretKey: '',
        isAutoCreate: 0
      },
      rules: {},
      isAutoCreateKey: '' + new Date().getTime()
    }
  },
  props: {
    platInfo: {
      default: () => ({
        id: ''
      }),
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
    isAddAcount: {
      type: Boolean,
      default: true
    },
    accountInfo: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    isAddAcount: {
      handler (val) {
        if (!val) {
          this.formquery = JSON.parse(JSON.stringify(this.accountInfo))
          this.formquery.openApiStatus = +this.formquery.openApiStatus
          this.formquery.isAutoCreate = +this.accountInfo.isAutoCreate || 0
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    addaccountVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    showIsAutoCreate,
    showOpenApiStatus,
    handleCancel () {
      this.addaccountVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.formquery.platId = this.platInfo.id
          if (this.isAddAcount) {
            const resp = await addPlatAccount(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.addaccountVisible = false
              this.$emit('addNewAccount')
            }
          } else {
            const resp = await updatePlat(this.formquery)
            if (resp.code === 200) {
              this.$message.success('配置成功!')
              this.addaccountVisible = false
              this.$emit('editAccount')
              this.$emit('changeAutoCreate', this.formquery.isAutoCreate)
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.add_account_modal .ant-modal-body {
  background-color: #eee;
  .add_account_wrapper {
    border-radius: 5px;
    background-color: #fff;
    height: 400px;
    overflow-y: auto;
  }
}
</style>
