<template>
  <a-modal
    v-model="batchCopyVisible"
    title="批量复制广告源"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="860"
    dialogClass="copy_source_modal"
  >
    <div class="copy_source_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" v-bind="layout">
        <a-row type="flex">
          <a-col :span="8">
            <a-form-model-item label="广告平台" prop="platId">
              <a-select
                allowClear
                placeholder="请选择广告平台"
                v-model="formquery.platId"
                @change="getTableList"
                :filter-option="filterOption"
                showSearch
              >
                <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="流量分组" prop="targetGroupId">
              <a-select placeholder="请选择流量分组" v-model="formquery.targetGroupId" @change="changeTargetGroupId">
                <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="A/B测试" prop="targetTestId">
              <a-select placeholder="请选择A/B测试" v-model="formquery.targetTestId" @change="getTableList">
                <a-select-option v-for="item in targetTestList" :key="item.testId" :value="item.testId">{{
                  item.groupName
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-alert
          style="font-size: 12px; margin: 0 20px"
          message="以下广告源已经绑定到当前应用的广告位，但未绑定当前流量分组以及AB测试分组，勾选提交后即可复制到当前分组下"
          banner
        />
        <a-table
          :key="tableKey"
          class="batch_copy_table"
          :pagination="false"
          :rowKey="(record, index) => index"
          :data-source="formquery.tableList"
          :scroll="{ y: 350 }"
          :row-selection="{
            onChange: handleRowChange,
            onSelect: handleRowSelect,
            onSelectAll: handleRowSelectAll,
            hideDefaultSelections: true,
            selectedRowKeys: selectTableItems
          }"
          bordered
        >
          <!-- 广告平台 -->
          <a-table-column align="left" dataIndex="platName" :width="120">
            <span slot="title" class="hd_tips">广告平台</span>
          </a-table-column>
          <!-- 广告源 -->
          <a-table-column align="left" dataIndex="name" :width="160">
            <span slot="title" class="hd_tips">广告源</span>
          </a-table-column>
          <!-- 价格 -->
          <a-table-column align="left" :width="180" dataIndex="bidfloor">
            <span slot="title" class="hd_tips">价格</span>
            <template slot-scope="text, record, index">
              <a-form-model-item
                v-if="record.settleType === 0"
                :prop="`tableList[${index}].bidfloor`"
                :rules="[{ required: true, message: '请输入价格', trigger: 'blur' }]"
              >
                <a-input size="small" placeholder="请输入价格" v-model.number.trim="record.bidfloor">
                  <span slot="addonAfter">￥</span>
                </a-input>
              </a-form-model-item>
              <span v-else>竞价</span>
            </template>
          </a-table-column>
          <!-- 状态 -->
          <a-table-column align="left" :width="120" dataIndex="sts">
            <span slot="title" class="hd_tips">复制后状态</span>
            <template slot-scope="text, record, index">
              <a-switch :checked="formquery.tableList[index].sts === 'A'" @click="changeSts($event, index)"></a-switch>
            </template>
          </a-table-column>
        </a-table>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  getGroupIdListByPlace,
  getBatchReplicationConfigAdsourceList,
  batchReplicationConfig,
  platformLinkList
} from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        placeId: undefined,
        targetGroupId: undefined,
        targetTestId: undefined,
        tableList: []
      },
      tableKey: '',
      groupList: [],
      platList: [],
      selectTableItems: [],
      columns: [
        {
          dataIndex: 'name',
          title: '广告源',
          align: 'left',
          width: 150,
          fixed: true
        },
        {
          dataIndex: 'platName',
          title: '广告平台',
          align: 'left',
          width: 150,
          fixed: true
        },
        {
          dataIndex: 'bidfloor',
          title: '价格',
          align: 'left',
          width: 150,
          fixed: true,
          scopedSlots: { customRender: 'bidfloor' }
        }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  async mounted () {
    this.formquery = {
      placeId: this.query.placeId,
      targetGroupId: this.query.groupId,
      targetTestId: this.query.testId,
      tableList: []
    }
    this.platformLinkList()
    await this.getGroups()
    await this.getTableList()
  },
  computed: {
    batchCopyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    currentTargetGroup () {
      if (this.formquery.targetGroupId) {
        return this.groupList.find((item) => item.id === this.formquery.targetGroupId) || []
      } else {
        return {}
      }
    },
    targetTestList () {
      if (this.currentTargetGroup.id) {
        return this.currentTargetGroup.abTests
      }
      return []
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async platformLinkList () {
      const resp = await platformLinkList(this.query.appId)
      this.platList = resp.data || []
    },
    handleRowChange (selectedRowKeys, selectedRows) {
      this.selectTableItems = selectedRowKeys
    },
    handleRowSelect (record, selected, selectedRows) {
      this.formquery.adSourceVoList = selectedRows
    },
    handleRowSelectAll (selected, selectedRows, changeRows) {
      this.formquery.adSourceVoList = selectedRows
    },
    changeSts (e, index) {
      this.formquery.tableList[index].sts = e ? 'A' : 'S'
    },
    changeTargetGroupId () {
      this.formquery.tableList = []
      if (this.targetTestList.length === 1) {
        this.formquery.targetTestId = this.targetTestList[0].testId
      } else {
        this.formquery.targetTestId = undefined
      }
      this.getTableList()
    },
    async getGroups () {
      const resp = await getGroupIdListByPlace(this.query.placeId)
      this.groupList = resp.data.groups
    },
    async getTableList () {
      if (!this.formquery.placeId || !this.formquery.targetGroupId || !this.formquery.targetTestId) {
        return
      }
      const query = {
        placeId: this.formquery.placeId,
        targetGroupId: this.formquery.targetGroupId,
        targetTestId: this.formquery.targetTestId,
        platId: this.formquery.platId
      }
      const resp = await getBatchReplicationConfigAdsourceList(query)
      this.formquery.adSourceVoList = []
      this.selectTableItems = []
      this.formquery.tableList = resp.data || []
      this.tableKey = '' + Math.random()
    },
    handleCancel () {
      this.batchCopyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.formquery.adSourceVoList.length === 0) {
            this.$message.error('至少选择一个广告源')
            return
          }
          const resp = await batchReplicationConfig(this.formquery)
          if (resp.code === 200) {
            this.$message.success('复制成功！')
            this.$emit('batchCopySuccess')
            this.batchCopyVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.copy_source_modal .ant-modal-body {
  // background: #eee;
  .copy_source_wrapper {
    padding: 30px 10px;
    height: 60vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 5px;
    .batch_copy_table {
      margin: 20px;
    }
  }
}
::v-deep .copy_source_modal{
        .ant-table-scroll .ant-table-body .ant-table-tbody {
        tr {
          height: 90px;
          td {
            padding: 0;
            .ant-form-item {
              display: flex;
              margin-bottom: 0;
              height: 40px;
              margin-top: 20px;
              .ant-form-item-control {
                line-height: 20px;
              }
              .ant-form-explain,
              .ant-form-extra {
                min-height: 16px;
                line-height: 16px;
                font-size: 12px;
              }
              .ant-select {
                padding: 0;
              }
              .ant-switch {
                margin-top: 8px;
              }
            }
          }
        }
      }

}
</style>
