<template>
  <a-modal
    title="广告源异常诊断分析"
    v-model="ifWarning"
    @onCancel="handleCancel"
    width="70%"
    dialogClass="waring_modal"
  >
    <a-form-model :model="query" class="allianceSerachForm">
      <div class="form_box" style="margin-bottom: 10px">
        <div class="date_box">
          <h4>
            {{ dataList.placeName }}
            <p class="placeId">({{ dataList.placeId }})</p>
          </h4>
          <a-range-picker v-model="rangePickerDate" @change="ChangeDate" :allowClear="false">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </div>
        <a-descriptions layout="vertical" :column="5">
          <a-descriptions-item label="所属广告平台"> {{ dataList.platName || '--' }} </a-descriptions-item>
          <a-descriptions-item label="广告位/代码位ID">{{ dataList.platPlaceId || '--' }}</a-descriptions-item>
          <a-descriptions-item label="广告位请求量">{{ dataList.advRequest || '--' }}</a-descriptions-item>
          <a-descriptions-item label="广告位返回量">{{ dataList.advResponse || '0' }}</a-descriptions-item>
          <a-descriptions-item label="广告位填充率">{{ dataList.advFilledRate || '0' }}%</a-descriptions-item>
        </a-descriptions>
      </div>
      <div class="form_box">
        <div class="date_box">
          <h4>错误码诊断</h4>
        </div>
        <a-table
          :data-source="dataList.errorVoList"
          :pagination="false"
          row-key="code"
          :loading="isLoading"
          @change="handleSort"
          bordered
        >
          <!-- 错误码 -->
          <a-table-column align="left" dataIndex="code" :width="80">
            <span slot="title" class="hd_tips">错误码</span>
          </a-table-column>
          <!-- 错误说明 -->
          <a-table-column align="left" dataIndex="msg" :width="100">
            <span slot="title" class="hd_tips">错误说明</span>
          </a-table-column>
          <!-- 请求失败次数 -->
          <a-table-column
            align="left"
            dataIndex="requestFailNum"
            :width="150"
            :sorter="true"
            :sortDirections="['descend', 'ascend']"
            :sortOrder="sortInfo.requestFailNum"
          >
            <span slot="title" class="hd_tips">请求失败次数</span>
          </a-table-column>
          <!-- 强求失败占比 -->
          <a-table-column
            align="left"
            dataIndex="requestFailProportion"
            :width="160"
            :sorter="true"
            :sortDirections="['descend', 'ascend']"
            :sortOrder="sortInfo.requestFailProportion"
          >
            <span slot="title" class="hd_tips">请求失败占比</span>
            <template slot-scope="requestFailProportion">
              <ProgressBar :per="+requestFailProportion"></ProgressBar>
            </template>
          </a-table-column>
          <!-- 优化建议 -->
          <a-table-column align="left" dataIndex="optimizeSuggest" :width="200">
            <span slot="title" class="hd_tips">优化建议</span>
            <template slot-scope="optimizeSuggest">
              <Suggested :suggest="optimizeSuggest" />
            </template>
          </a-table-column>
        </a-table>
        <a-pagination
          class="pagination"
          show-size-changer
          :current="query.page"
          :pageSize="query.limit"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </a-form-model>
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { getSourceError } from '@/api/common'
import mixDate from '@/mixins/initDate'
import ProgressBar from './ProgressBar'
import Suggested from './Suggested.vue'

export default {
  mixins: [mixDate],
  components: { ProgressBar, Suggested },
  data () {
    return {
      dataList: [],
      rangePickerDate: null,
      total: 0,
      isLoading: false,
      query: {
        appId: this.parameters.appId,
        placeId: this.parameters.placeId,
        sourceId: this.parameters.id,
        testId: this.parameters.testId,
        groupId: this.parameters.groupId,
        dateEnd: '',
        dateStart: '',
        page: 1,
        limit: 10
      },
      sortInfo: {
        requestFailNum: false,
        requestFailProportion: false
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    parameters: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    ifWarning: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {},
  mounted () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(0, 0)
    this.rangePickerDate = [start, end]
    this.query.dateEnd = endDate
    this.query.dateStart = startDate
    this.getSourceError()
  },
  methods: {
    // 时间改变事件
    ChangeDate (date, dateString) {
      this.query = {
        ...this.query,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.getSourceError()
    },
    handleCancel () {
      this.$emit('modalCancel')
    },
    // 获取table数据
    async getSourceError () {
      this.isLoading = true
      const resp = await getSourceError(this.query)
      this.dataList = resp.data || []
      this.total = resp.data.total
      this.isLoading = false
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getSourceError()
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getSourceError()
    },
    // 列表字段排序
    handleSort (pagination, filters, sorter) {
      const status = {
        descend: 'desc', // 降序
        ascend: 'asc' // 升序
      }
      this.query.sortBy = sorter.field
      this.query.sortOrder = status[sorter.order] || ''
      for (const key in this.sortInfo) {
        this.sortInfo[key] = false
      }
      this.sortInfo[sorter.field] = sorter.order
      this.query.page = 1
      this.getSourceError()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal-body {
    background: #eee;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-descriptions-item {
    font-weight: 600px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-placeholder {
    border-bottom: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
    padding: 10px;
  }
  .ant-table-wrapper {
    padding-bottom: 30px;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    width: 20%;
  }
  .ant-table-thead > tr:first-child > th:nth-child(2) {
    width: 20%;
  }
  .ant-descriptions-item-content {
    color: #333333;
  }
  .ant-descriptions-row > td {
    padding-bottom: 5px;
    padding-top: 10px;
  }
  .ant-descriptions-item-label {
    font-size: 14px;
    color: #999999;
  }
  .ant-calendar-picker {
    width: 25%;
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input {
    background: #fafafa;
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input input.ant-calendar-range-picker-input {
    font-size: 12px;
    width: 45%;
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input .ant-calendar-picker-icon {
    display: block;
    padding-left: 5px;
    color: #b3b3b3;
  }
  .ant-progress-outer {
    width: 50%;
  }
  .ant-descriptions-item-colon::after {
    content: '';
  }
}
.form_box {
  background: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  .ant-pagination {
    margin-top: 10px;
  }
  .date_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 8px;
    h4 {
      margin-bottom: 0;
      padding-bottom: 0;
      display: flex;
      .placeId {
        font-size: 12px;
        margin-left: 10px;
        color: #999999;
      }
    }
  }
}
</style>
