<template>
  <a-modal v-model="batchExportVisible" title="批量导入" @onCancel="handleCancel" @ok="handleSubmit" width="900px">
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <div class="form_head">
        <div class="app_box">
          <h2>应用</h2>
          <span>{{ appName }}</span>
        </div>
        <div class="place_box">
          <h2>广告位</h2>
          <span>{{ placeName }}</span>
        </div>
      </div>
      <div class="first_step">
        <div class="step_box">
          <h3>第一步</h3>
          <!-- 广告平台 -->
          <a-form-model-item
            label="广告平台"
            :rules="[{ required: true, message: '请输入广告平台', trigger: 'change' }]"
            prop="platId"
          >
            <a-select
              allowClear
              showSearch
              :filter-option="filterOption"
              placeholder="请选择广告平台"
              @change="changePlatId"
              style="width: 300px"
              v-model="formquery.platId"
            >
              <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 广告平台账户 -->
          <a-form-model-item
            label="广告平台账户"
            :rules="[{ required: true, message: '请输入广告平台账户', trigger: 'change' }]"
            prop="platAccountId"
          >
            <a-select
              allowClear
              showSearch
              :filter-option="filterOption"
              placeholder="请选择广告平台账户"
              @change="changePlatAccountId"
              style="width: 300px"
              v-model="formquery.platAccountId"
            >
              <a-select-option v-for="item in accountList" :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 应用ID -->
          <a-form-model-item
            label="应用ID"
            :rules="[{ required: true, message: '请输入应用ID', trigger: 'blur' }]"
            prop="platAppId"
          >
            <a-input
              v-if="platAppIdIsEdit"
              style="width: 300px"
              v-model.trim="formquery.platAppId"
              placeholder="请输入应用ID"
              ref="platAppIdInput"
            ></a-input>
            <div class="edit_platappid" v-else>
              {{ formquery.platAppId }}
              <span @click="handleEditPlatAppId">编辑</span>
            </div>
          </a-form-model-item>
          <!-- 下载按钮 -->
          <a-form-model-item label="下载模板">
            <a-button
              :loading="downLoading"
              @click="handleDownload"
              type="primary"
            ><a-icon v-if="!downLoading" type="cloud-download" />点击下载模板</a-button
            >
          </a-form-model-item>
        </div>
      </div>
      <div class="first_step">
        <div class="step_box">
          <h3>第二步</h3>
          <!-- 下载按钮 -->
          <a-form-model-item
            label="上传文件"
            :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]"
            prop="file"
          >
            <a-upload
              ref="upload"
              accept=".xlsx"
              action=""
              :with-credentials="true"
              :customRequest="httpRequest"
              :before-upload="beforeUpload"
              :remove="removeFile"
              :fileList="fileList"
              :multiple="false"
            >
              <a-button><a-icon type="upload" />点击上传文件</a-button>
            </a-upload>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { alliancePlatformList } from '@/api/common'
import { getPlatAccounts } from '@/api/aggregate'
import request from '@/utils/request'
import excel from '@/utils/excel'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 }
      },
      rules: {},
      formquery: {
        platId: undefined,
        platAccountId: undefined,
        platAppId: undefined,
        file: undefined
      },
      platList: [],
      platName: '',
      accountList: [],
      accountInfo: {},
      platAppIdIsEdit: true,
      downLoading: false,
      fileList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    query: {
      default: () => ({}),
      type: Object
    },
    placeName: {
      default: '',
      type: String
    },
    appName: {
      default: '',
      type: String
    },
    positionId: {
      default: 0,
      type: Number
    }
  },
  watch: {},
  created () {
    this.alliancePlatformList()
  },
  computed: {
    batchExportVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async alliancePlatformList () {
      const resp = await alliancePlatformList()
      this.platList = resp.data || []
      this.platList = this.platList.filter((item) => item.accessType === '0')
    },
    async changePlatId (e) {
      this.platName = this.platList.find((item) => item.id === e)
        ? this.platList.find((item) => item.id === e).name
        : undefined
      const resp = await getPlatAccounts({
        platId: this.formquery.platId,
        appId: this.query.appId
      })
      this.formquery.platAccountId = undefined
      this.formquery.platAppId = undefined
      this.platAppIdIsEdit = true
      this.accountList = resp.data || []
    },
    changePlatAccountId (e) {
      this.formquery.platAppId = undefined
      this.accountInfo = this.accountList.find((item) => item.id === e)
      this.accountInfo &&
        this.accountInfo.platAppId &&
        (this.formquery.platAppId = this.accountInfo.platAppId) &&
        (this.platAppIdIsEdit = false)
      !this.accountInfo.platAppId && (this.platAppIdIsEdit = true)
      // this.accountInfo && this.accountInfo.platAppId && this.$refs.ruleForm.validate(() => {})
    },
    handleEditPlatAppId () {
      this.platAppIdIsEdit = true
      this.$nextTick(() => {
        this.$refs.platAppIdInput.focus()
      })
    },
    async handleDownload () {
      if (!this.formquery.platId) {
        return this.$message.warning('请选择广告平台')
      }
      if (!this.formquery.platAccountId) {
        return this.$message.warning('请选择广告平台账户')
      }
      if (!this.formquery.platAppId) {
        return this.$message.warning('请输入应用ID')
      }
      this.downLoading = true
      try {
        const res = await this.exportToExcel()
        await excel(res, `【${this.platName}】代码位模板.xlsx`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
        this.downLoading = false
      }
    },
    exportToExcel () {
      return request.post(
        '/aggregate/downloadAdsourceTemplate',
        {
          platId: this.formquery.platId,
          position: this.positionId
        },
        {
          responseType: 'blob'
        }
      )
    },
    httpRequest (param) {},
    beforeUpload (file) {
      this.formquery.file = file
      this.fileList = [file]
      return false
    },
    removeFile (file) {
      this.formquery.file = undefined
      this.fileList = []
    },
    handleCancel () {
      this.batchExportVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const fd = new FormData()
          fd.append('file', this.formquery.file)
          const parameter = {
            appId: this.query.appId,
            placeId: this.query.placeId,
            groupId: this.query.groupId,
            testId: this.query.testId,
            platId: this.formquery.platId,
            platAppId: this.formquery.platAppId,
            platAccountId: this.formquery.platAccountId,
            oldPlatAppId: this.accountInfo.platAppId ? this.accountInfo.platAppId : undefined,
            type: this.accountInfo.platAppId ? 1 : 0
          }
          fd.append('parameter', JSON.stringify(parameter))
          const url = '/aggregate/bulkImportAdsource'
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          request.post(url, fd, config).then((res) => {
            if (res.code === 200) {
              this.$message.success('导入成功！')
              this.batchExportVisible = false
              this.$emit('batchExportSuccess')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form_head {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  .app_box,
  .place_box {
    width: 40%;
    display: flex;
    justify-content: center;
    // align-items: center;
    flex-direction: column;
    text-align: left;
    h2 {
      font-size: 16px;
      color: #999;
    }
  }
}
.first_step {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .step_box {
    width: 80%;
    h3 {
      font-size: 15px;
      font-weight: 600;
    }
  }
  .edit_platappid {
    span {
      margin: 0 10px;
      color: @primary-color;
      cursor: pointer;
    }
  }
}
</style>
