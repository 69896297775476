<template>
  <a-modal
    v-model="addAbtestVisible"
    :title="(isAdd ? '添加' : '修改') + 'A/B测试分组'"
    @onCancel="handleCancel"
    :width="900"
    dialogClass="abtest_modal"
  >
    <template slot="footer">
      <a-button
        v-if="origin === 'https://app.mediatom.cn'"
        style="margin-right: 550px"
        type="link"
        target="_blank"
        href="https://app.mediatom.cn/docs/#/?blogid=118"
      >了解A/B测试的使用</a-button
      >
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button type="primary" key="submit" @click="handleSubmit"> 确定 </a-button>
    </template>
    <div class="form_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout" :wrapperCol="{ span: 14 }">
        <a-form-model-item class="form_item" has-feedback label="应用">
          {{ appName }}
        </a-form-model-item>
        <a-form-model-item class="form_item" has-feedback label="广告位">
          {{ placeName }}
        </a-form-model-item>
        <a-form-model-item class="form_item" has-feedback label="流量分组">
          {{ groupName }}
        </a-form-model-item>
        <a-form-model-item
          class="form_item"
          has-feedback
          label="A/B测试名称"
          prop="testName"
          :rules="[{ required: true, message: '请输入A/B测试名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入A/B测试名称" v-model.trim="formquery.testName" />
        </a-form-model-item>
        <a-form-model-item class="form_item" has-feedback prop="type">
          <m-tips
            slot="label"
            :content="`请求:按每次广告请求划分。用户:按用户设备id划分。如对比缓存效果，需使按用户划分`"
            :title="'流量划分形式'"
            :width="200"
          ></m-tips>
          <a-radio-group :disabled="!isAdd" v-model="formquery.type">
            <a-radio :value="0">请求</a-radio>
            <a-radio :value="1">用户</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item class="form_item" has-feedback label="创建分组">
          <a-button type="primary" @click="handleAddTest">添加分组</a-button>
        </a-form-model-item>
        <a-table
          style="width: 85%; margin: 0 auto"
          size="small"
          bordered
          :rowKey="(record, index) => index"
          :data-source="
            formquery.abTestList.filter((item) => {
              return item.testId !== 'default'
            })
          "
          :pagination="false"
        >
          <a-table-column align="left" key="testId" title="分组名称" width="160px">
            <template slot-scope="text, record, index">
              <a-form-model-item
                class="table_form_item"
                has-feedback
                :prop="`abTestList[${index}].groupName`"
                :rules="[{ required: true, message: '请输入分组名称', trigger: 'blur' }]"
              >
                <a-input size="small" style="width: 140px" placeholder="分组名称" v-model.trim="record.groupName" />
              </a-form-model-item>
            </template>
          </a-table-column>
          <!-- 分组名称 -->
          <a-table-column align="left" key="isContrast" title="是否为对照组" width="110px">
            <template slot-scope="text, record, index">
              <a-switch size="small" :checked="record.isContrast === 1" @change="changeIsContrast(record, index)" />
            </template>
          </a-table-column>
          <a-table-column align="left" v-if="formquery.type === 1" title="比例" width="80px">
            <template slot-scope="text, record">
              {{ getProportion(record) + '%' }}
            </template>
          </a-table-column>
          <a-table-column align="left" :title="formquery.type === 0 ? '比例' : '用户区间'" width="200px">
            <template slot-scope="text, record, index">
              <!-- 比例 -->
              <a-form-model-item
                style="display: flex"
                class="table_form_item"
                v-if="formquery.type === 0"
                :prop="`abTestList[${index}].proportion`"
                :rules="[
                  {
                    required: true,
                    message: '比例不能为空',
                    trigger: 'blur'
                  },
                  ,
                  {
                    required: true,
                    trigger: 'blur',
                    validator: proportionValidator
                  }
                ]"
              >
                <a-input style="width: 70px" v-model.number.trim="record.proportion" size="small" placeholder="">
                  <span slot="suffix">%</span>
                </a-input>
              </a-form-model-item>

              <!-- 区间 -->
              <div v-if="formquery.type === 1">
                <div v-for="(item, i) in record.intervalList" :key="i" style="display: flex; height: 100%">
                  <a-form-model-item
                    style="margin-bottom: 0"
                    :prop="`abTestList[${index}].intervalList[${i}][0]`"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        validator: intervalListValidator
                      }
                    ]"
                  >
                    <a-input-number
                      v-model.number.trim="record.intervalList[i][0]"
                      size="small"
                      :max="100"
                      :min="1"
                      style="width: 100px; margin-left: 5px"
                      placeholder="区间"
                    />
                  </a-form-model-item>

                  <a-form-model-item
                    style="margin-bottom: 0"
                    :prop="`abTestList[${index}].intervalList[${i}][1]`"
                    :rules="[
                      {
                        required: true,
                        trigger: 'blur',
                        validator: intervalListValidator
                      }
                    ]"
                  >
                    <a-input-number
                      v-model.number.trim="record.intervalList[i][1]"
                      size="small"
                      :max="100"
                      :min="1"
                      style="width: 100px; margin-left: 5px"
                      placeholder="区间"
                    />
                  </a-form-model-item>
                  <div
                    v-if="formquery.abTestList[index].intervalList.length > 1"
                    style="line-height: 40px; margin-left: 5px; cursor: pointer"
                    @click="closeintervalList(index, i)"
                  >
                    <a-icon style="color: red" type="close-circle" />
                  </div>
                </div>
                <a-button
                  size="small"
                  type="link"
                  @click="handleAddintervalList(index)"
                ><a-icon type="plus" />
                  添加区间
                </a-button>
              </div>
            </template>
          </a-table-column>
          <a-table-column align="left" title="操作" width="80px">
            <template slot-scope="text, record, index">
              <a-button
                v-if="!record.testId && index !== 0 && formquery.abTestList.length > 2"
                type="error"
                size="small"
                @click="closeTest(index)"
              >
                删除
              </a-button>
              <a-switch
                size="small"
                v-if="record.testId && (testDisabled || record.sts !== 'A')"
                :checked="record.sts === 'A'"
                @change="changeTestSts($event, index)"
              />
            </template>
          </a-table-column>
        </a-table>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import { addAbtest, updateAbtest } from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 20 }
      },
      formquery: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    abtestInfo: {
      default: () => ({
        id: '',
        name: '',
        abTests: [
          {
            testId: '',
            testName: '',
            type: 1,
            groupName: 'A组',
            isContrast: 0,
            proportion: 50,
            sts: 'A',
            intervalList: [[1, 1]]
          },
          {
            testId: '',
            testName: '',
            groupName: 'B组',
            isContrast: 0,
            proportion: 50,
            sts: 'A',
            intervalList: [[1, 1]]
          }
        ]
      }),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    placeName: {
      default: '',
      type: String
    },
    appName: {
      default: '',
      type: String
    },
    groupName: {
      default: '',
      type: String
    }
  },
  watch: {
    'formquery.abTestList': {
      handler (val) {
        if ((!this.isAdd && val.length === 3) || (this.isAdd && val.length === 2)) {
          val.forEach((item) => {
            if (item.testId !== 'default') {
              item.sts = 'A'
            }
          })
        }
      }
    },
    abtestInfo: {
      handler (val) {
        let testName = ''
        let type = 0
        if (!this.isAdd) {
          testName = val.abTests ? val.abTests[0].testName : ''
          type = val.abTests ? val.abTests[0].type : 0
        } else {
          testName = ''
          type = 0
        }
        this.formquery = {
          testName,
          type,
          groupId: this.query.groupId,
          placeId: this.query.placeId,
          abTestList: JSON.parse(JSON.stringify(this.abtestInfo.abTests)) || [
            {
              groupName: 'A组',
              proportion: 50,
              testId: '',
              isContrast: 0,
              sts: 'A',
              intervalList: [[1, 1]]
            },
            {
              groupName: 'B组',
              proportion: 50,
              isContrast: 0,
              testId: '',
              sts: 'A',
              intervalList: [[1, 1]]
            }
          ]
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    testDisabled () {
      return this.formquery.abTestList.filter((item) => item.sts === 'A').length > 2
    },
    addAbtestVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      origin: (state) => state.autoweb.origin
    })
  },
  methods: {
    changeIsContrast (data, index) {
      this.formquery.abTestList.map((item, idx) => {
        item.isContrast = (!data.isContrast && index) === idx ? 1 : 0
      })
    },
    // 点击删除分组
    closeTest (index) {
      this.formquery.abTestList.splice(index, 1)
    },
    // 修改状态
    changeTestSts (sts, index) {
      this.formquery.abTestList[index].sts = sts ? 'A' : 'S'
    },
    // 点击添加分组
    handleAddTest () {
      // 修改模式
      if (!this.isAdd) {
        const len = this.formquery.abTestList.length
        // 在默认分组之前加一项
        this.formquery.abTestList.splice(len - 1, 0, {
          groupName: '',
          proportion: 0,
          isContrast: 0,
          sts: 'A',
          intervalList: [[1, 1]]
        })
      } else {
        // 新建模式
        this.formquery.abTestList.push({
          groupName: '',
          proportion: 0,
          isContrast: 0,
          sts: 'A',
          intervalList: [[1, 1]]
        })
      }
    },
    // 添加区间
    handleAddintervalList (index) {
      this.formquery.abTestList[index].intervalList.push([1, 1])
    },
    // 删除区间
    closeintervalList (index, i) {
      this.formquery.abTestList[index].intervalList.splice(i, 1)
    },
    // 获取区间比例数值
    getProportion (record) {
      let proportion = 0
      record.intervalList.forEach((item) => {
        const p = item[1] - item[0] + 1
        proportion += p
      })
      return proportion
    },
    // 比例输入限制
    proportionValidator (rule, value, callback) {
      if (+value < 0) {
        callback(new Error('比例不能为负数！'))
      } else if (typeof +value !== 'number') {
        callback(new Error('比例必须为数字！'))
      } else {
        callback()
      }
    },
    // 区间输入限制
    intervalListValidator (rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入区间值！'))
      } else {
        return callback()
      }
    },
    handleCancel () {
      this.addAbtestVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const formquery = JSON.parse(JSON.stringify(this.formquery))
          // 校验 分组中有一项为对照组
          const result = formquery.abTestList.find((ele) => ele.isContrast === 1)
          if (!result) return this.$message.error('请设置对照组')
          // 验证比例总和
          let num = 0
          formquery.abTestList.forEach((item) => {
            if (item.sts === 'A') {
              const proportion = +item.proportion
              num = +num + proportion
            }
          })
          if (formquery.type === 1) {
            // 区间数组
            let arr = []
            // 区间总数
            let sum = 0
            // 区间组数
            let arrNum = 0
            formquery.abTestList.forEach((item, index) => {
              if (item.sts === 'A') {
                if (item.intervalList) {
                  item.intervalList.forEach((child, c) => {
                    arrNum++
                    arr.push(child[0])
                    arr.push(child[1])
                    sum = sum + (child[1] - child[0])
                  })
                }
              }
            })
            // 所有区间排序
            arr = arr.sort((a, b) => {
              return a - b
            })
            const len = arr.length
            // 1. 区间值总和与区间数相加等于100
            // 2. 区间的第一项必须为1
            // 3. 区间的最后一项必须为100
            if (sum + arrNum !== 100 || arr[0] !== 1 || arr[len - 1] !== 100) {
              return this.$message.error('输入区间有误！')
            }
          } else {
            if (num !== 100) {
              return this.$message.error('比例总和必须为100')
            }
          }
          if (this.isAdd) {
            const resp = await addAbtest(formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeTestInfo')
              this.addAbtestVisible = false
            }
          } else {
            const resp = await updateAbtest(formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeTestInfo')
              this.addAbtestVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.form_wrapper {
  background-color: #fff;
  height: 60vh;
  overflow-y: auto;
  border-radius: 5px;
  border-left: 10px solid #fff;
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 5px fade(@primary-color, 3%);
    background: fade(@primary-color, 20%);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px fade(@primary-color, 3%);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .form_item {
    margin-bottom: 10px;
  }
  .table_form_item {
    margin-bottom: 0;
  }
  .has-error .ant-form-explain {
    white-space: nowrap;
  }
}
</style>
