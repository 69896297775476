/**
 * 初始化表单中的自动创建广告源表单
 * @param {*} platInfo 广告平台信息
 * @param {*} formquery 表单对象
 * @param {*} positionId 广告样式
 */
export function initAutoCreateFormDefault (platInfo, formquery, positionId) {
  // 优量汇
  if (+platInfo.id === 2) {
    // 原生自渲染
    // 原生模版
    // 原生混合
    if (+positionId === 4 || +positionId === 6 || (+positionId === 10 && formquery.adStyle)) {
      if (+positionId === 4 || +positionId === 6) {
        // 广告场景
        formquery.scene = 1
      }
      // 模版样式
      formquery.templateIdList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
    }
    // 开屏
    if (+positionId === 1) {
      // 广告场景 不区分
      formquery.scene = 'FLASH_OTHER'
      // 渲染类型 开屏
      formquery.crtType = 'FLASH'
      // 素材类型
      formquery.adCrtTypeList = ['FLASH_IMAGE_V', 'FLASH_VIDEO_V']
    }
    // 激励视频
    if (+positionId === 5) {
      // 广告场景 过关奖励
      formquery.scene = 'REWARDED_APP_PASS'
      // 渲染类型 激励浏览+激励视频
      formquery.crtType = 'ALL_DIRECTION'
    }
  }
  // 快手
  if (+platInfo.id === 3) {
    // 原生自渲染
    // 原生模版
    if (+positionId === 4 || +positionId === 6 || (+positionId === 10 && formquery.adStyle)) {
      // 素材类型 全选
      formquery.adCrtTypeList = ['1', '2', '5', '6']
      // 视频声音 静音
      formquery.videoVoiceControl = 1
      // 视频自动播放 有网下
      formquery.videoAutoPlayType = 1
      // 模版样式全选
      formquery.templateIdList = ['1', '2', '3', '4', '5', '14', '15', '16', '17', '18', '19']
    }
    // 插屏
    if (+positionId === 3) {
      // 素材类型 全选
      formquery.adCrtTypeList = ['1', '2', '5', '6']
    }
    // 开屏
    if (+positionId === 1) {
      // 跳过按钮不显示
      formquery.countdownShow = 0
    }
    // 激励视频
    if (+positionId === 5) {
      // 视频方向 竖屏
      formquery.adCrtTypeList[0] = 1
      // 奖励名称 虚拟金币
      formquery.rewardedType = 1
    }
  }
  // 穿山甲
  if (+platInfo.id === 1) {
    // 原生模版
    if (+positionId === 6 || (+positionId === 10 && +formquery.adStyle === 1)) {
      // 素材类型 图片+视频
      formquery.adCrtTypeList[0] = 3
      // 视频声音 静音
      formquery.videoVoiceControl = 1
      // 模版样式 全选
      formquery.templateIdList = ['1', '2', '3', '4', '5', '6', '7']
    }
    // 原生自渲染
    if (+positionId === 4 || (+positionId === 10 && +formquery.adStyle === 2)) {
      // 广告样式 全选
      formquery.templateIdList = ['1', '2', '3', '4', '5', '6']
    }
    // 插屏
    if (+positionId === 3) {
      // 屏幕方向 竖屏
      formquery.adCrtTypeList[0] = 1
      // 广告铺开大小 半屏
      formquery.adRolloutSize = 2
      // 视频声音 静音
      formquery.videoVoiceControl = 1
      // 素材类型 图片加视频单选
      formquery.adCrtTypeList[0] = 3
    }
    // 开屏
    if (+positionId === 1) {
      // 屏幕方向 竖屏
      formquery.adCrtTypeList[0] = 1
    }
    // 激励视频
    if (+positionId === 5) {
      // 屏幕方向 竖屏
      formquery.adCrtTypeList[0] = 1
    }
  }
  // 百度
  if (+platInfo.id === 6) {
    // (原生混合 && adadstyle === 1) || 原生模版
    if ((+positionId === 10 && +formquery.adStyle === 1) || +positionId === 6) {
      formquery.drawTypeList = ['1', '2', '3', '4', '5', '6']
    }
    // (原生混合 && adadstyle === 2) || 原生自渲染
    if ((+positionId === 10 && +formquery.adStyle === 2) || +positionId === 4) {
      // 样式类型
      formquery.drawTypeList = ['33', '34', '35', '36', '28', '29', '30', '42', '37', '41']
      // 图片设置
      formquery.imgSizeList = ['1', '2', '3', '4', '5', '6', '7', '8']
      // 视频设置
      formquery.videoSizeList = ['9', '10']
    }
  }
}
