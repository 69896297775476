<template>
  <a-modal
    v-model="frequencyVisible"
    dialogClass="frequency_modal"
    title="频次控制"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <div class="frequency_wrapper">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <!-- 广告平台名称 -->
        <a-form-model-item label="广告平台">
          {{ platName }}
        </a-form-model-item>
        <!-- 广告源ID -->
        <a-form-model-item label="广告源">
          {{ sourceInfo.name }}
        </a-form-model-item>
        <!-- 总请求上限（天） -->
        <a-form-model-item prop="requestFreq">
          <m-tips
            slot="label"
            :content="`设置该广告源请求次数上限/天。不填，则表示不限制；`"
            :title="'总请求上限（天）'"
            :width="150"
          ></m-tips>
          <a-input
            style="width: 300px"
            placeholder="不限"
            v-model.trim="formquery.requestFreq"
            @change="handleInputProp($event, 'requestFreq')"
          />
        </a-form-model-item>
        <!-- 单设备请求上限（天） -->
        <a-form-model-item prop="deviceRequestFreq">
          <m-tips
            slot="label"
            :content="`设置单个用户在该广告源请求次数上限/天。不填，则表示不限制；`"
            :title="'单设备请求上限（天）'"
            :width="150"
          ></m-tips>
          <a-input
            style="width: 300px"
            placeholder="不限"
            v-model.trim="formquery.deviceRequestFreq"
            @change="handleInputProp($event, 'deviceRequestFreq')"
          />
        </a-form-model-item>
        <template v-if="type === 'rtb'">
          <!-- 单设备请求间隔（秒） -->
          <a-form-model-item prop="deviceRequestInterval">
            <m-tips
              slot="label"
              :content="`设置本次请求到下一次请求之间的时间间隔/秒。不填，则表示不限`"
              :title="'单设备请求间隔（秒）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.deviceRequestInterval"
              @change="handleInputProp($event, 'deviceRequestInterval')"
            />
          </a-form-model-item>
          <!-- 总展示上限（天） -->
          <a-form-model-item prop="impressFreqDay">
            <m-tips
              slot="label"
              :content="`设置该广告源展示次数上限/天。不填，则表示不限制；`"
              :title="'总展示上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.impressFreqDay"
              @change="handleInputProp($event, 'impressFreqDay')"
            />
          </a-form-model-item>
          <!-- 总点击上限（天） -->
          <a-form-model-item prop="clickFreqDay">
            <m-tips
              slot="label"
              :content="`设置该广告源点击次数上限/天。不填，则表示不限制；`"
              :title="'总点击上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.clickFreqDay"
              @change="handleInputProp($event, 'clickFreqDay')"
            />
          </a-form-model-item>
        </template>
        <template v-if="type !== 'rtb'">
          <!-- 单设备请求间隔（秒） -->
          <a-form-model-item prop="requestInterval">
            <m-tips
              slot="label"
              :content="`设置本次请求到下一次请求之间的时间间隔/秒。不填，则表示不限`"
              :title="'单设备请求间隔（秒）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.requestInterval"
              @change="handleInputProp($event, 'requestInterval')"
            />
          </a-form-model-item>
          <!-- 总展示上限（天） -->
          <a-form-model-item prop="impressFreq">
            <m-tips
              slot="label"
              :content="`设置该广告源展示次数上限/天。不填，则表示不限制；`"
              :title="'总展示上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.impressFreq"
              @change="handleInputProp($event, 'impressFreq')"
            />
          </a-form-model-item>
          <!-- 总点击上限（天） -->
          <a-form-model-item prop="clickFreq">
            <m-tips
              slot="label"
              :content="`设置该广告源点击次数上限/天。不填，则表示不限制；`"
              :title="'总点击上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.clickFreq"
              @change="handleInputProp($event, 'clickFreq')"
            />
          </a-form-model-item>
          <!-- 单设备展示上限（天） -->
          <a-form-model-item prop="impressFreqDay">
            <m-tips
              slot="label"
              :content="`设置单个用户的广告展示次数上限/天。不填，则表示不限制；`"
              :title="'单设备展示上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.impressFreqDay"
              @change="handleInputProp($event, 'impressFreqDay')"
            />
          </a-form-model-item>
          <!-- 单设备展示上限（小时） -->
          <a-form-model-item prop="impressFreqHour">
            <m-tips
              slot="label"
              :content="`设置单个用户的广告展示次数上限/小时。不填，则表示不限制；`"
              :title="'单设备展示上限（小时）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.impressFreqHour"
              @change="handleInputProp($event, 'impressFreqHour')"
            />
          </a-form-model-item>
          <!-- 单设备点击上限（天） -->
          <a-form-model-item prop="clickInterval">
            <m-tips
              slot="label"
              :content="`设置单个用户的广告点击次数上限/天。不填，则表示不限制；`"
              :title="'单设备点击上限（天）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.clickInterval"
              @change="handleInputProp($event, 'clickInterval')"
            />
          </a-form-model-item>
          <!-- 展示间隔（秒） -->
          <a-form-model-item prop="impressInterval">
            <m-tips
              slot="label"
              :content="`设置本次展示到下一次请求之间的时间间隔/秒。不填，则表示不限`"
              :title="'展示间隔（秒）'"
              :width="150"
            ></m-tips>
            <a-input
              style="width: 300px"
              placeholder="不限"
              v-model.trim="formquery.impressInterval"
              @change="handleInputProp($event, 'impressInterval')"
            />
          </a-form-model-item>
        </template>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { platformLinkList,
  adsourceAdvancedSetup,
  frequencyControl
} from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        requestFreq: undefined,
        requestInterval: undefined,
        impressFreq: undefined,
        clickFreq: undefined,
        impressFreqDay: undefined,
        impressFreqHour: undefined,
        clickInterval: undefined,
        impressInterval: undefined
      },
      rules: {
        // os: [{ required: true, message: '请选择系统平台', trigger: 'change' }],
        // name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        // bundle: [{ required: true, message: '请输入应用包名', trigger: 'blur' }]
      },
      platList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    type: {
      type: String,
      default: ''
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        this.formquery = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    frequencyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    platName () {
      return this.platList.find((item) => item.id === this.sourceInfo.platId)
        ? this.platList.find((item) => item.id === this.sourceInfo.platId).name
        : ''
    }
  },
  mounted () {
    this.platformLinkList()
  },
  methods: {
    handleInputProp (e, prop) {
      if (+e.target.value < 1) {
        this.formquery[prop] = undefined
      } else if (+e.target.value >= 1) {
        this.formquery[prop] = Math.ceil(+e.target.value)
      } else {
        this.formquery[prop] = undefined
      }
    },
    async platformLinkList () {
      const resp = await platformLinkList(this.query.appId)
      this.platList = resp.data || []
    },
    handleCancel () {
      this.frequencyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let url = ''
          let query = {}
          if (this.type === 'rtb') {
            url = frequencyControl
            query = {
              id: +this.formquery.id,
              requestFreq: this.formquery.requestFreq,
              deviceRequestFreq: this.formquery.deviceRequestFreq,
              deviceRequestInterval: this.formquery.deviceRequestInterval,
              impressFreqDay: this.formquery.impressFreqDay,
              clickFreqDay: this.formquery.clickFreqDay
            }
          } else {
            this.formquery.placeId = this.query.placeId
            this.formquery.groupId = this.query.groupId
            this.formquery.testId = this.query.testId
            this.formquery.slotId = this.formquery.id
            url = adsourceAdvancedSetup
            query = this.formquery
          }
          const resp = await url(query)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSource')
            this.frequencyVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.frequency_modal .ant-modal-body {
  background-color: #eee;
  .frequency_wrapper {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
  }
}
</style>
